import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Parametro } from 'src/app/models/class/parametro.class';
import { Sistema } from 'src/app/models/class/sistema.class';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';
import { BotonGrillaComponent } from '../componentes-grilla/boton-grilla/boton-grilla.component';

@Component({
  selector: 'app-parametro',
  templateUrl: './parametro.component.html',
  styleUrls: ['./parametro.component.scss']
})
export class ParametroComponent implements OnInit {

  bodyParametro = new Parametro('', 0, 0, '', '', '');
  lt_sistema: Sistema[] = [];
  actualiza: boolean = false;
  frameworkComponents: any;
  columnas_grilla: ColDef[] = [
    { field: 'nom_sistema', headerName: "Sistema" },
    { field: 'id_parametro', headerName: "ID Parametro" },
    { field: 'nom_parametro', headerName: "Nombre Parametro" },
    { field: 'valor_parametro', headerName: "Valor Parametro" },
    {
      field: '',
      cellRenderer: 'botonRender',
      cellRendererParams: {
        onClick: this.btnEditar.bind(this),
        label: 'Editar'
      },
      cellStyle: { textAlign: "center" }
    }
  ];
  datosGrilla = [];
  parametroForm: UntypedFormGroup = new UntypedFormGroup({
    tipo: new UntypedFormControl(),
    id_sistema: new UntypedFormControl(),
    id_parametro: new UntypedFormControl(),
    nom_parametro: new UntypedFormControl(),
    valor_parametro: new UntypedFormControl(),
    nombre_usuario: new UntypedFormControl()
  });

  constructor(private servicios: ServiceService, private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {
    if (!this.jwt.isTokenExpired()) {
      this.frameworkComponents = {
        botonRender: BotonGrillaComponent,
      }
      this.bodyParametro.nombre_usuario = this.jwt.getUser();
    }
  }

  ngOnInit(): void {
    this.cargaSistemas();
    this.cargaParametros();
  }

  onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }

  cargaSistemas(): void {
    try {
      let body: Sistema = { tipo: 'Seleccionar', id_sistema: 0, nom_sistema: '', nombre_usuario: this.jwt.getUser() };
      this.servicios.mantenedorSeguridad(body, 'sistema').subscribe(res => {
        if (res.estado === 'OK') {
          this.lt_sistema.push( { tipo: '', id_sistema: 0, nom_sistema: "", nombre_usuario: "" } );
          res.resultado.forEach((val: any) => {
            this.lt_sistema.push(val);
          });
        }
      });
    }
    catch (ex) { console.log(ex); }
  }
  onChangeSistema(id_sistema: any): void {
    if(!this.actualiza){
      if (id_sistema != -1) {
        this.cargaParametrosXsistema(id_sistema);
      }else{
        this.datosGrilla = [];
      }
    }
  }
  cargaParametros(): void {
    try {
      let body = new Parametro('Seleccionar', 0, 0, '', '', this.jwt.getUser());
      this.servicios.mantenedorSeguridad(body, 'parametro').subscribe(res => {
        if (res.estado === 'OK') {
          this.datosGrilla = res.resultado;
        }
      });
    }
    catch (ex) { console.log(ex);}
  }
  cargaParametrosXsistema(id_sistema: number): void {
    try {
      let body = new Parametro('Seleccionar', id_sistema, 0, '', '', this.jwt.getUser());
      this.servicios.mantenedorSeguridad(body, 'parametro').subscribe(res => {
        if (res.estado === 'OK') {
          this.datosGrilla = res.resultado;
        }
      });
    }
    catch (ex) { console.log(ex);}
  }
  limpiarParametro(): void {
    this.parametroForm.patchValue({
      id_sistema: '',
      id_parametro: '',
      nom_parametro: '',
      valor_parametro: ''
    });
    this.parametroForm.controls['id_sistema'].enable();
    this.parametroForm.controls['id_parametro'].enable();
    this.parametroForm.controls['nom_parametro'].enable();
    this.parametroForm.controls['valor_parametro'].enable();
    this.bodyParametro.id_sistema = 0;
    this.bodyParametro.id_parametro = 0;
    this.bodyParametro.nom_parametro = '';
    this.bodyParametro.valor_parametro = '';
    this.actualiza = false;
  }
  mantenedorParametro(tipo: string) {
    switch (tipo) {
      case "ELIMINAR":
        if (confirm("¿Esta seguro que desea Eliminar?")) {
          if (this.parametroForm.valid) {
            this.bodyParametro.tipo = 'Eliminar';
            this.bodyParametro.id_parametro = this.parametroForm.value.id_parametro;
            this.servicios.mantenedorSeguridad(this.bodyParametro, 'parametro').subscribe(res => {
              if (res.estado === 'OK') {
                this.sweetAlert.sweetOK("Parametro Eliminado","");
                this.limpiarParametro();
                this.cargaParametros();
              } else {
              }
            });
          } else { }
        }else {  }
        break;
      case "AGREGAR":
        this.parametroForm.controls['id_parametro'].disable();
        if (this.parametroForm.valid) {
          this.bodyParametro.tipo = 'Insertar';
          this.bodyParametro.nom_parametro = this.parametroForm.value.nom_parametro.toUpperCase();
          this.bodyParametro.valor_parametro = this.parametroForm.value.valor_parametro;
          this.servicios.mantenedorSeguridad(this.bodyParametro, 'parametro').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Parametro ingresado","");
              this.limpiarParametro();
              this.cargaParametros();
            } else {
            }
          });
        } else { }
        break;
      case "ACTUALIZAR":
        if (this.parametroForm.valid) {
          this.bodyParametro.tipo = 'Actualizar';
          this.bodyParametro.id_sistema = this.parametroForm.value.id_sistema;
          this.bodyParametro.id_parametro = this.parametroForm.value.id_parametro;
          this.bodyParametro.nom_parametro = this.parametroForm.value.nom_parametro.toUpperCase();
          this.bodyParametro.valor_parametro = this.parametroForm.value.valor_parametro;
          this.servicios.mantenedorSeguridad(this.bodyParametro, 'parametro').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Parametro actualizado","");
              this.limpiarParametro();
              this.cargaParametros();
            } else {
            }
          });
        }
        break;
    }
  }
  btnEditar(e: any) {
    this.actualiza = true;
    this.parametroForm.patchValue({
      id_sistema: e.rowData.id_sistema,
      id_parametro: e.rowData.id_parametro,
      nom_parametro: e.rowData.nom_parametro,
      valor_parametro: e.rowData.valor_parametro,
    });
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.parametroForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
}
