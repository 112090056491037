import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Perfil } from 'src/app/models/class/perfil.class';
import { Sistema } from 'src/app/models/class/sistema.class';
import { UsuarioPerfil } from 'src/app/models/class/usuario-perfil.class';
import { Usuario } from 'src/app/models/class/usuario.class';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';
import { BotonGrillaComponent } from '../componentes-grilla/boton-grilla/boton-grilla.component';

@Component({
  selector: 'app-usuario-perfil',
  templateUrl: './usuario-perfil.component.html',
  styleUrls: ['./usuario-perfil.component.scss']
})
export class UsuarioPerfilComponent implements OnInit {

  bodyUsuPer = new UsuarioPerfil('', '', 0, 0, false, '');
  lt_sistema: Sistema[] = [];
  lt_perfil: Perfil[] = [];
  actualiza: boolean = false;
  habilita: boolean = false;
  habilita2: boolean = false;
  btnAgrega: boolean = true;
  frameworkComponents: any;
  columnas_grilla: ColDef[] = [
    { field: 'rut_usuario', headerName: "Rut Usuario" },
    { field: 'nom_perfil', headerName: "Nombre Perfil" },
    { field: 'vigente', headerName: "Vigente" },
    {
      field: '', headerName: "",
      cellRenderer: 'botonRender',
      cellRendererParams: {
        onClick: this.btnEditar.bind(this),
        label: 'Editar'
      },
      cellStyle: { textAlign: "center" }
    }
  ];
  public defaultColDef: ColDef = { //ancho minimo de cada columna en el grid
    minWidth:300,
  };
  datosGrilla = [];
  usuperForm: UntypedFormGroup = new UntypedFormGroup({
    tipo: new UntypedFormControl(),
    id_sistema: new UntypedFormControl('',[Validators.required]),
    rut_usuario: new UntypedFormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(12)]),
    id_usuario: new UntypedFormControl('',[Validators.required]),
    id_perfil: new UntypedFormControl('',[Validators.required]),
    vigente: new UntypedFormControl(false),
    nombre_usuario: new UntypedFormControl()
  });

  constructor(private servicios: ServiceService, private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {
    if (!this.jwt.isTokenExpired()) {
      this.frameworkComponents = {
        botonRender: BotonGrillaComponent,
      }
      this.bodyUsuPer.nombre_usuario = this.jwt.getUser();
    }
  }

  ngOnInit(): void {
    this.cargaSistemas();
    this.limpiarUsuPer();
    this.usuperForm.value.vigente = false;
    this.usuperForm.controls['vigente'].disable();
  }
  onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }


  cargaSistemas(): void {
    try {
      let body: Sistema = { tipo: 'Seleccionar', id_sistema: 0, nom_sistema: '', nombre_usuario: this.jwt.getUser() };
      this.servicios.mantenedorSeguridad(body, 'sistema').subscribe(res => {
        if (res.estado === 'OK') {
          if (res.resultado.length > 0) {
            var algo: any = {id_sistema: -1, nom_sistema: "-- Seleccione Sistema --"};
            this.lt_sistema.push(algo);
            res.resultado.forEach((val: any) => {
              this.lt_sistema.push(val);
            });
            this.usuperForm.get('id_sistema')?.setValue(-1)
          } else { this.lt_sistema = []; }
        }
      });
    }
    catch (ex) { console.log(ex); }
  }
  cargaPerfiles(id_sistema: number): void {
    try {
      let body: Perfil = { tipo: 'Seleccionar', id_sistema: id_sistema, id_perfil: 0, nom_perfil: '', nombre_usuario: this.jwt.getUser() };
      this.servicios.mantenedorSeguridad(body, 'perfil').subscribe(res => {
        this.lt_perfil = []
        if (res.estado === 'OK') {
          if (res.resultado.length > 0) {
            var algo: any = {id_perfil: -1, nom_perfil: "-- Seleccione Perfil --"};
            this.lt_perfil.push(algo);
            res.resultado.forEach((val: any) => {
              this.lt_perfil.push(val);
            });
          } else { this.lt_perfil = []; 
            var algo: any = {id_perfil: -1, nom_perfil: "-- Seleccione perfil --"};
            this.lt_perfil.push(algo,); 
            this.usuperForm.get('id_perfil')?.setValue(-1);
          }
          this.habilita = true;
          this.usuperForm.controls['rut_usuario'].enable();
          this.usuperForm.controls['id_usuario'].enable();
        }
      });
    }
    catch (ex) { console.log(ex); }

  }
  onChangeSistema(id_sistema: any): void {
    this.actualiza = false;
    if (id_sistema != -1) {
      this.habilitaForm(true, id_sistema);
    } else {
      this.habilitaForm(false, -1);
    }
  }

  onChangePerfil(id_perfil: any): void {
    if (id_perfil > -1) {
      this.btnAgrega = false;
    } else {
      this.btnAgrega = true;
    }
  }

  habilitaForm(estado: boolean, id_sistema: number): void {
    switch (estado) {
      case false:
        // this.usuperForm.get('rut_usuario')?.disable();
        document.getElementById('txtRutUsuario')?.setAttribute('readonly', 'true');
        document.getElementById('txtIdUsuario')?.setAttribute('readonly', 'true');
        document.getElementById('cbPerfil')?.setAttribute('readonly', 'true');
        this.datosGrilla = [];
        this.bodyUsuPer.id_sistema = 0;
        break;
      case true:
        document.getElementById('txtRutUsuario')?.removeAttribute('readonly');
        document.getElementById('txtIdUsuario')?.removeAttribute('readonly');
        document.getElementById('cbPerfil')?.removeAttribute('readonly');
        this.bodyUsuPer.id_sistema = id_sistema;
        this.cargaPerfiles(id_sistema);
        this.cargaUsuarioPerfiles(id_sistema, "");
        break;
    }
  }
  
  cargaUsuarioPerfiles(id_sistema: number, rut_usuario: string): void {
    try {
      let body = new UsuarioPerfil('Seleccionar', rut_usuario, id_sistema, 0, false, this.jwt.getUser());
      this.servicios.mantenedorSeguridad(body, 'usuario-perfil').subscribe(res => {
        if (res.estado === 'OK') {
          this.datosGrilla = res.resultado;
        }
      });
    }
    catch (ex) { console.log(ex); }
  }

  limpiarUsuPer(): void {
    this.usuperForm.patchValue({
      rut_usuario: '',
      id_usuario: '',
      id_perfil: '',
      vigente: this.usuperForm.get('vigente')?.setValue(false)
    });
    this.actualiza = false;
    this.usuperForm.get('id_sistema')?.setValue(null)
    if (this.usuperForm.get('id_sistema')?.value != null) {
      this.usuperForm.controls['id_usuario'].enable();
      this.usuperForm.controls['rut_usuario'].enable();
    }
    else{
      this.usuperForm.controls['id_usuario'].disable();
      this.usuperForm.controls['rut_usuario'].disable();
    }
    this.usuperForm.controls['id_perfil'].disable();
    this.usuperForm.controls['vigente'].disable();
    this.usuperForm.controls['id_sistema'].enable();
    this.datosGrilla = [];
    this.usuperForm.get('id_sistema')?.setValue(-1)
    this.btnAgrega = true;
  }
  
  mantenedorUsuPer(tipo: string) {
    switch (tipo) {
      case "ELIMINAR":
        if (confirm("¿Esta seguro que desea Eliminar?")) {
          if (this.usuperForm.valid) {
            this.bodyUsuPer.tipo = 'Eliminar';
            this.bodyUsuPer.rut_usuario = this.usuperForm.get('rut_usuario')?.value.toUpperCase();
            this.bodyUsuPer.id_perfil = this.usuperForm.get('id_perfil')?.value;

            this.servicios.mantenedorSeguridad(this.bodyUsuPer, 'usuario-perfil').subscribe(res => {
              if (res.estado === 'OK') {
                this.sweetAlert.sweetOK("Usuario - Perfil eliminado","");
                this.limpiarUsuPer();
              } else {
                this.sweetAlert.sweetErr("No se pudo eliminar","");
              }
            });
          } else {  }
        } else { }
        break;
      case "AGREGAR":
        if (this.usuperForm.valid && this.usuperForm.controls['id_perfil'].enabled) {
          this.bodyUsuPer.tipo = 'Insertar';
          this.bodyUsuPer.rut_usuario = this.usuperForm.get('rut_usuario')?.value.toUpperCase();
          this.bodyUsuPer.id_perfil = this.usuperForm.get('id_perfil')?.value.toUpperCase();
          this.bodyUsuPer.vigente = this.usuperForm.value.vigente;
          this.servicios.mantenedorSeguridad(this.bodyUsuPer, 'usuario-perfil').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Usuario - Perfil ingresado","");
              this.limpiarUsuPer();
            } else {
              this.sweetAlert.sweetErr("No se pudo ingresar","");
            }
          });
        } else { 
          if (this.usuperForm.controls['id_perfil'].enabled) {
            this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString())
          }else{
            this.sweetAlert.sweetWarning("No se pudo realizar la acción","Debe buscar por rut o id usuario y el usuario debe existir")
          }
        }
        break;
      case "ACTUALIZAR":
        console.log(this.usuperForm.valid);
        console.log(this.usuperForm.get('id_perfil')?.value)
        console.log(this.usuperForm.get('id_perfil')?.value > -1);
        
        if (this.usuperForm.valid && this.usuperForm.get('id_perfil')?.value > -1) {
          this.bodyUsuPer.tipo = 'Actualizar';
          this.bodyUsuPer.rut_usuario = this.usuperForm.get('rut_usuario')?.value.toUpperCase();
          this.bodyUsuPer.id_perfil = this.usuperForm.get('id_perfil')?.value;
          this.bodyUsuPer.vigente = this.usuperForm.value.vigente;
          this.servicios.mantenedorSeguridad(this.bodyUsuPer, 'usuario-perfil').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Usuario - Perfil actualizado","");
              this.limpiarUsuPer();
            } else {
              this.sweetAlert.sweetErr("No se pudo actualizar","");
            }
          });
        } else { this.sweetAlert.sweetWarning("No se pudo realizar la acción","Seleccione un perfil ");}
        break;
    }
  }
  btnEditar(e: any) {
    this.actualiza = true;
    let body: Usuario = { tipo: 'Seleccionar', rut_usuario: e.rowData.rut_usuario, id_usuario: '', nombre: '', ape_paterno: '', ape_materno: '', email: '', funcionario: false, nombre_usuario: this.jwt.getUser(), nombre_completo: '' };
    this.servicios.mantenedorSeguridad(body, 'usuario').subscribe(res => {
      if (res.estado === 'OK') {
          this.habilita2 = true;
          this.usuperForm.patchValue({
            rut_usuario: e.rowData.rut_usuario,
            id_usuario: res.resultado[0].id_usuario,
            id_perfil: e.rowData.id_perfil,
            vigente: e.rowData.vigente
          });
          this.usuperForm.controls['id_sistema'].disable();
          this.usuperForm.controls['id_usuario'].disable();
          this.usuperForm.controls['rut_usuario'].disable();
          this.usuperForm.controls['id_perfil'].enable();
          this.usuperForm.controls['vigente'].enable();
      } else {
        this.sweetAlert.sweetWarning("Usuario NO ENCONTRADO","");
      }
    });
  }

  buscarUsuario(tipo: string): void {
    try {
      if (this.usuperForm.get('rut_usuario')?.value && this.usuperForm.get('rut_usuario')?.value.trim() !== '' || this.usuperForm.get('id_usuario')?.value && this.usuperForm.get('id_usuario')?.value.trim() !== '') {
      let body:any;
      if (tipo == "rut") {
        let rut = this.usuperForm.get('rut_usuario')?.value.toUpperCase();
        let bodys: Usuario = { tipo: 'Seleccionar', rut_usuario: rut, id_usuario: '', nombre: '', ape_paterno: '', ape_materno: '', email: '', funcionario: false, nombre_usuario: this.jwt.getUser(), nombre_completo: '' };
        body = bodys;
      }
      if (tipo == "usuario") {
        let bodys: Usuario = { tipo: 'Seleccionar', rut_usuario: '', id_usuario: this.usuperForm.get('id_usuario')?.value, nombre: '', ape_paterno: '', ape_materno: '', email: '', funcionario: false, nombre_usuario: this.jwt.getUser(), nombre_completo: '' };
        body = bodys;
      }
      this.servicios.mantenedorSeguridad(body, 'usuario').subscribe(res => {
        if (res.estado === 'OK') {
          this.usuperForm.get('id_perfil')?.setValue(-1)
          if (res.resultado.length > 0) {
            let element = document.getElementById('txtIdUsuario');
            if (element != null) {
              this.usuperForm.patchValue({
                id_usuario: res.resultado[0].id_usuario,
                rut_usuario: res.resultado[0].rut_usuario
              });
              this.habilita2 = true;
              this.cargaUsuarioPerfiles(this.bodyUsuPer.id_sistema, res.resultado[0].rut_usuario);
              this.usuperForm.controls['id_sistema'].disable();
              this.usuperForm.controls['rut_usuario'].disable();
              this.usuperForm.controls['id_usuario'].disable();
              this.usuperForm.controls['id_perfil'].enable();
              this.usuperForm.controls['vigente'].enable();
              this.usuperForm.get('vigente')?.setValue(true);
              
            }
          }else{
            this.usuperForm.controls['id_perfil'].disable();
            this.usuperForm.controls['vigente'].disable();
            this.sweetAlert.sweetWarning("Usuario no encontrado","");
          }
        } else {
          this.sweetAlert.sweetWarning("Usuario NO ENCONTRADO","");
        }
      });
    }
    }
    catch (ex) { console.log(ex); }

  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.usuperForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onRutInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const formattedValue = this.formatRut(inputValue);
    
    this.usuperForm.get('rut_usuario')?.setValue(formattedValue, { emitEvent: false });
  }

  formatRut(rut: string): string {
    const cleanRut = rut.replace(/[^\dkK0-9]/g, '').toUpperCase();
    const rutPart = cleanRut.slice(0, -1);
    const dvPart = cleanRut.slice(-1);
  
    return rutPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '-' + dvPart;
  }

}
