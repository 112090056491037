import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';

import { SistemaComponent } from './components/sistema/sistema.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AccesoComponent } from './components/acceso/acceso.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { UsuarioPerfilComponent } from './components/usuario-perfil/usuario-perfil.component';
import { PermisoComponent } from './components/permiso/permiso.component';
import { HighlightService } from './services/highlight.service';
import { ParametroComponent } from './components/parametro/parametro.component';
import { LogComponent } from './components/log/log.component';
import { RecuperaContrasenaComponent } from './components/recupera-contrasena/recupera-contrasena.component';
import { CambioContrasenaComponent } from './components/cambio-contrasena/cambio-contrasena.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { IsLoggedInterceptor } from './interceptors/is-logged.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    SistemaComponent,
    PerfilComponent,
    InicioComponent,
    HomeComponent,
    LoginComponent,
    AccesoComponent,
    UsuarioComponent,
    UsuarioPerfilComponent,
    PermisoComponent,
    ParametroComponent,
    LogComponent,
    RecuperaContrasenaComponent,
    CambioContrasenaComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbAlertModule,
    AgGridModule
  ],
  providers: 
  [
    HighlightService,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IsLoggedInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
