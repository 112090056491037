import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Perfil } from 'src/app/models/class/perfil.class';
import { Sistema } from 'src/app/models/class/sistema.class';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';
import { BotonGrillaComponent } from '../componentes-grilla/boton-grilla/boton-grilla.component';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  bodyPerfil = new Perfil('', 0, 0, '', '');
  lt_sistema: Sistema[] = [];
  actualiza: boolean = false;
  btnAgregar: boolean = true;
  frameworkComponents: any;
  columnas_grilla: ColDef[] = [
    { field: 'id_perfil', headerName: "ID Perfil" },
    { field: 'nom_perfil', headerName: "Nombre Perfil" },
    {
      field: '',
      cellRenderer: 'botonRender',
      cellRendererParams: {
        onClick: this.btnEditar.bind(this),
        label: 'Editar'
      },
      cellStyle: { textAlign: "center" }
    }
  ];
  public defaultColDef: ColDef = { //ancho minimo de cada columna en el grid
    minWidth:400,
  };
  datosGrilla = [];
  perfilForm: UntypedFormGroup = new UntypedFormGroup({
    tipo: new UntypedFormControl(),
    id_sistema: new UntypedFormControl('', [Validators.required]),
    id_perfil: new UntypedFormControl(),
    nom_perfil: new UntypedFormControl('', [Validators.minLength(4), Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]*$'), this.customSpacesValidator(3)]),
    nombre_usuario: new UntypedFormControl()
  });

  constructor(private servicios: ServiceService, private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {
    if (!this.jwt.isTokenExpired()) {
      this.frameworkComponents = {
        botonRender: BotonGrillaComponent,
      }
      this.bodyPerfil.nombre_usuario = this.jwt.getUser();
    }
  }

  ngOnInit(): void {
    this.cargaSistemas();
    this.EnableOrDisableInputForm('Disable');
  }
  onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }

  cargaSistemas(): void {
    try {
      let body: Sistema = { tipo: 'Seleccionar', id_sistema: 0, nom_sistema: '', nombre_usuario: this.jwt.getUser() };
      this.servicios.mantenedorSeguridad(body, 'sistema').subscribe(res => {
        if (res.estado === 'OK') {
          if (res.resultado.length > 0) {
            var algo: any = {id_sistema: -1, nom_sistema: "-- Seleccione Sistema --"};
            this.lt_sistema.push(algo);
            res.resultado.forEach((val: any) => {
              this.lt_sistema.push(val);
            });
            this.perfilForm.get('id_sistema')?.setValue(-1)
          } else { this.lt_sistema = []; }
        }
      });
    }
    catch (ex) { console.log(ex); }

  }

  onChangeSistema(id_sistema: any): void {
    if (id_sistema != -1) {
      this.habilitaForm(true, id_sistema);
      this.btnAgregar = false;
    } else {
      this.habilitaForm(false, -1);
    }
  }

  habilitaForm(estado: boolean, id_sistema: number): void {
    switch (estado) {
      case false:
        document.getElementById('txtNomPerfil')?.setAttribute('readonly', 'true');
        this.datosGrilla = [];
        this.bodyPerfil.id_sistema = 0;
        this.EnableOrDisableInputForm('Disable');
        break;
      case true:
        document.getElementById('txtNomPerfil')?.removeAttribute('readonly');
        this.bodyPerfil.id_sistema = id_sistema;
        this.cargaPerfiles(id_sistema);
        this.EnableOrDisableInputForm('Enable');
        break;
    }

  }

  cargaPerfiles(id_sistema: number): void {
    try {
      let body = new Perfil('Seleccionar', id_sistema, 0, '', this.jwt.getUser());
      this.servicios.mantenedorSeguridad(body, 'perfil').subscribe(res => {
        if (res.estado === 'OK') {
          this.datosGrilla = res.resultado;
        }
      });
    }
    catch (ex) { console.log(ex); }
  }

  limpiarPerfil(): void {
    this.perfilForm.patchValue({
      id_perfil: '',
      nom_perfil: '',
    });
    this.bodyPerfil.id_perfil = 0;
    this.bodyPerfil.nom_perfil = '';
    this.EnableOrDisableInputForm('Disable');
    this.actualiza = false;
    this.datosGrilla = [];
    this.perfilForm.get('nom_perfil')?.markAsUntouched();
    this.perfilForm.get('id_sistema')?.markAsUntouched();
    this.perfilForm.get('id_sistema')?.setValue(-1)
    this.btnAgregar = true;
  }

  mantenedorPerfil(tipo: string) {
    switch (tipo) {
      case "ELIMINAR":
        if (confirm("¿Esta seguro que desea Eliminar?")) {
          if (this.perfilForm.valid) {
            this.bodyPerfil.tipo = 'Eliminar';
            this.bodyPerfil.id_perfil = this.perfilForm.value.id_perfil;
            this.servicios.mantenedorSeguridad(this.bodyPerfil, 'perfil').subscribe(res => {
              if (res.estado === 'OK') {
                this.sweetAlert.sweetOK("Perfil eliminado","");
                this.limpiaCrud();
              } else {
              }
            });
          } else {  }
        }else {  }
        break;
      case "AGREGAR":
        if (this.perfilForm.valid) {
          this.bodyPerfil.tipo = 'Insertar';
          this.bodyPerfil.nom_perfil = this.perfilForm.value.nom_perfil.toUpperCase();
          this.servicios.mantenedorSeguridad(this.bodyPerfil, 'perfil').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Perfil ingresado","");
              this.limpiaCrud();
            } else {
              this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());
            }
          });
        } else {
          this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());}
        break;
      case "ACTUALIZAR":
        if (this.perfilForm.valid) {
          this.bodyPerfil.tipo = 'Actualizar';
          this.bodyPerfil.id_perfil = this.perfilForm.value.id_perfil;
          this.bodyPerfil.nom_perfil = this.perfilForm.value.nom_perfil.toUpperCase();
          this.servicios.mantenedorSeguridad(this.bodyPerfil, 'perfil').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Perfil actualizado","");
              this.limpiaCrud();
            } else {
              this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());}
          });
        } else {
          this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());}
        break;
    }
  }
  btnEditar(e: any) {
    this.actualiza = true;
    this.perfilForm.patchValue({
      id_perfil: e.rowData.id_perfil,
      nom_perfil: e.rowData.nom_perfil,
    });
    this.EnableOrDisableInputForm('Enable');
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.perfilForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(" "+name);
      }
    }
    return invalid;
  }
  
  customSpacesValidator(maxSpaces: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const spaces = control.value.split(' ').length - 1;
      if (spaces > maxSpaces) {
        return { spaces: true };
      }
      return null;
    };
  }

  EnableOrDisableInputForm (tipo: string) {
    if (tipo == "Enable") {
      this.perfilForm.controls['nom_perfil'].enable();
      this.perfilForm.controls['id_sistema'].disable();
    }
    else {
      this.perfilForm.controls['nom_perfil'].disable();
      this.perfilForm.controls['id_sistema'].enable();
    }
  }

  limpiaCrud(){
    this.perfilForm.patchValue({
      id_perfil: '',
      nom_perfil: '',
    });
    this.bodyPerfil.id_perfil = 0;
    this.bodyPerfil.nom_perfil = '';
    this.actualiza = false;
    this.perfilForm.get('nom_perfil')?.markAsUntouched();
    this.perfilForm.get('id_sistema')?.markAsUntouched();
    this.cargaPerfiles(this.bodyPerfil.id_sistema);
  }

}