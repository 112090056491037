import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccesoComponent } from './components/acceso/acceso.component';
import { CambioContrasenaComponent } from './components/cambio-contrasena/cambio-contrasena.component';
import { HomeComponent } from './components/home/home.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { LogComponent } from './components/log/log.component';
import { LoginComponent } from './components/login/login.component';
import { ParametroComponent } from './components/parametro/parametro.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { PermisoComponent } from './components/permiso/permiso.component';
import { RecuperaContrasenaComponent } from './components/recupera-contrasena/recupera-contrasena.component';
import { SistemaComponent } from './components/sistema/sistema.component';
import { UsuarioPerfilComponent } from './components/usuario-perfil/usuario-perfil.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'recuperar-contrasena', component: RecuperaContrasenaComponent },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard], children:
      [
        { path: 'inicio', component: InicioComponent, canActivate: [AuthGuard] },
        { path: 'sistema', component: SistemaComponent, canActivate: [AuthGuard] },
        { path: 'acceso', component: AccesoComponent, canActivate: [AuthGuard] },
        { path: 'perfil', component: PerfilComponent, canActivate: [AuthGuard] },
        { path: 'usuario', component: UsuarioComponent, canActivate: [AuthGuard] },
        { path: 'usuario-perfil', component: UsuarioPerfilComponent, canActivate: [AuthGuard] },
        { path: 'permiso', component: PermisoComponent, canActivate: [AuthGuard] },
        { path: 'parametro', component: ParametroComponent, canActivate: [AuthGuard] },
        { path: 'log', component: LogComponent, canActivate: [AuthGuard] },
        { path: 'cambio-contrasena', component: CambioContrasenaComponent, canActivate: [AuthGuard] },
      ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
