<div class="container">
    <div class="col-5 mt-sm-4 mt-3 mb-sm-3 mb-2">
        <h3>Mantenedor Usuario Perfil</h3>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col">
            <div class="card-principal">
                <form id="formPerfil" class="contact-form" [formGroup]="usuperForm">
                    <div class="container mt-sm-4">
                        <div class="row">
                            <div class="col">
                                <div class="form-floating">
                                    <select id="cbSistema" formControlName="id_sistema" class="form-select"
                                        placeholder="Seleccione Sistema" #t (change)="onChangeSistema(t.value)">
                                        <option *ngFor="let sistema of lt_sistema" [value]="sistema.id_sistema">
                                            {{sistema.nom_sistema}}
                                        </option>
                                    </select>
                                    <label for="cbSistema">Sistema</label>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-20"></div>
                        <div class="row">
                            <div class="col">
                                <div class="form-floating input-group">
                                    <input type="text" formControlName="rut_usuario" [attr.disabled]="!habilita ? '' : null" 
                                            class="form-control text-uppercase" id="txtRutUsuario" (input)="onRutInput($event)" autocomplete="off"
                                            maxlength="12" minlength="8" readonly required>
                                        <label for="txtRutUsuario">Rut Usuario</label>
                                    <button class="btn btn-outline-secondary" type="button" [attr.disabled]="!habilita ? '' : null"
                                        (click)="buscarUsuario('rut')">Buscar</button>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="form-floating">
                                    <select id="cbPerfil" formControlName="id_perfil" class="form-select" #p (change)="onChangePerfil(p.value)"
                                        [attr.disabled]="!habilita2 ? '' : null" placeholder="Seleccione Perfil" readonly required>
                                        <option *ngFor="let perfil of lt_perfil" [value]="perfil.id_perfil">
                                            {{perfil.nom_perfil}}
                                        </option>
                                    </select>
                                    <label for="cbPerfil">Perfil</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-floating input-group">
                                    <input type="text" [attr.disabled]="!habilita ? '' : null"
                                    class="form-control" id="txtIdUsuario" formControlName="id_usuario" readonly required>
                                    <label for="txtIdUsuario">ID Usuario</label>
                                    <button class="btn btn-outline-secondary" type="button" [attr.disabled]="!habilita ? '' : null"
                                        (click)="buscarUsuario('usuario')">Buscar</button>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="form-floating">
                                    <div class="form-check form-switch form-switch-lg">
                                      <input id="chkVigente" class="form-check-input" type="checkbox" formControlName="vigente">
                                      <label class="form-check-label ms-3 mt-2" for="chkVigente"> Vigente</label>
                                    </div>
                                  </div>
                                <!-- <div class="form-floating">
                                    <div class="form-check" style="padding-top: 15px;">
                                        <input id="chkVigente" formControlName="vigente" type="checkbox"
                                            class="form-check-input" value="" [attr.disabled]="!habilita2 ? '' : null">
                                        <label class="form-check-label" for="chkVigente">
                                            Vigente
                                        </label>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div *ngIf="sistemForm.controls['sistema'].invalid && (sistemForm.controls['sistema'].dirty || sistemForm.controls['sistema'].touched)">
                                <div class="help-block with-errors text-start ps-1" style="color: red; font-size: 12px; ">
                                    No puede ser vació, contener espacios ni números (_ soportado) minlength 4 maxlength 30.
                                </div>
                            </div> -->
                        </div>
                        <div class="spacer-20"></div>
                        <div class="d-flex justify-content-left">
                            <div *ngIf="!actualiza" class="col-md-3 d-grid gap-2">
                                <button type="submit" class="btn btn-success btn-lg" [disabled]="btnAgrega" (click)="mantenedorUsuPer('AGREGAR')">
                                    <i class="fas fa-plus-circle"></i>
                                    Agregar</button>
                            </div>
                            <div *ngIf="actualiza" class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-success btn-lg"
                                    (click)="mantenedorUsuPer('ACTUALIZAR')"><i class="fas fa-plus-circle"></i>
                                    Actualizar</button>
                            </div>
                            <div *ngIf="actualiza" class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-danger btn-lg" (click)="mantenedorUsuPer('ELIMINAR')"><i
                                        class="fas fa-minus-circle"></i>
                                    Eliminar</button>
                            </div>
                            <div class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-warning btn-lg" (click)="limpiarUsuPer()"><i
                                        class="fas fa-exclamation-circle"></i>
                                    Cancelar</button>
                            </div>
                        </div>
                        <div class="text-center border-top mb-3 mt-4"></div>
                        <h4 class="mb-3">Registros</h4>
                        <div class="row mt-sm-4 mt-2 mb-sm-2 ps-sm-3 pe-sm-3">
                            <div class="col-sm card-table">
                                <ag-grid-angular style="height: 300px;" class="ag-theme-alpine" [rowData]="datosGrilla"
                                    [columnDefs]="columnas_grilla" [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
                                    (firstDataRendered)="onFirstDataRendered($event)">
                                </ag-grid-angular>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>