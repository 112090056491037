import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { Perfil } from 'src/app/models/class/perfil.class';
import { GPermiso } from 'src/app/models/class/permiso.class';
import { Sistema } from 'src/app/models/class/sistema.class';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { CheckboxGrillaComponent } from '../componentes-grilla/check-grilla/check-grilla.component';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';

@Component({
  selector: 'app-permiso',
  templateUrl: './permiso.component.html',
  styleUrls: ['./permiso.component.scss']
})
export class PermisoComponent implements OnInit {

  bodyGPermiso = new GPermiso('', 0, 0, [], '');
  lt_sistema: Sistema[] = [];
  lt_perfil: Perfil[] = [];
  actualiza: boolean = false;
  habilita: boolean = false;
  btnGuardar:boolean = true;
  frameworkComponents: any;
  rowSelection: any = 'multiple';
  gridAcceso: any;
  gridPermiso: any;
  columna_grilla_acceso: ColDef[] = [
    {
      field: 'sel',
      headerName: "Sel",
      cellRenderer: 'checkboxRender',
      cellStyle: { textAlign: "center" },
      maxWidth: 70,
    },
    { field: 'nom_acceso', headerName: "Nombre Acceso", minWidth: 296, }
  ];
  columna_grilla_permiso: ColDef[] = [
    { field: 'sel', headerName: "Sel", cellStyle: { textAlign: "center" }, cellRenderer: 'checkboxRender', maxWidth: 70},
    { field: 'nom_acceso', headerName: "Nombre Acceso" , minWidth: 253},
    { field: 'insertar', headerName: "Insertar", cellStyle: { textAlign: "center" }, cellRenderer: 'checkboxRender',  maxWidth: 90, },
    { field: 'seleccionar', headerName: "Buscar", cellStyle: { textAlign: "center" }, cellRenderer: 'checkboxRender',  maxWidth: 90, },
    { field: 'actualizar', headerName: "Actualizar", cellStyle: { textAlign: "center" }, cellRenderer: 'checkboxRender',  maxWidth: 90, },
    { field: 'eliminar', headerName: "Eliminar", cellStyle: { textAlign: "center" }, cellRenderer: 'checkboxRender', maxWidth: 90, },
  ];
  datos_grilla_acceso: any[] = [];
  datos_grilla_permiso: any[] = [];
  permisoForm: UntypedFormGroup = new UntypedFormGroup({
    tipo: new UntypedFormControl(),
    id_sistema: new UntypedFormControl('',[Validators.required]),
    id_perfil: new UntypedFormControl('',[Validators.required]),
    id_acceso: new UntypedFormControl(),
    nom_acceso: new UntypedFormControl(),
    nombre_usuario: new UntypedFormControl()
  });


  constructor(private servicios: ServiceService, private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {
    if (!this.jwt.isTokenExpired()) {
      this.bodyGPermiso.nombre_usuario = this.jwt.getUser();
      this.frameworkComponents = {
        checkboxRender: CheckboxGrillaComponent,
      }
    }
  }

  ngOnInit(): void {
    this.cargaSistemas();
    this.permisoForm.controls['id_perfil'].disable();
  }
  onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }
  onGridAccesoReady(params: any) {
    this.gridAcceso = params.api;
  }
  onGridPermisoReady(params: any) {
    this.gridPermiso = params.api;
  }

  cargaSistemas(): void {
    try {
      let body: Sistema = { tipo: 'Seleccionar', id_sistema: 0, nom_sistema: '', nombre_usuario: this.jwt.getUser() };
      this.servicios.mantenedorSeguridad(body, 'sistema').subscribe(res => {
        if (res.estado === 'OK') {
          if (res.resultado.length > 0) {
            var algo: any = {id_sistema: -1, nom_sistema: "-- Seleccione Sistema --"};
            this.lt_sistema.push(algo);
            res.resultado.forEach((val: any) => {
              this.lt_sistema.push(val);
            });
            this.permisoForm.get('id_sistema')?.setValue(-1)
          } else { this.lt_sistema = []; }
        }
      });
    }
    catch (ex) { console.log(ex); }
  }
  cargaPerfiles(id_sistema: number): void {
    try {
      let body: Perfil = { tipo: 'Seleccionar', id_sistema: id_sistema, id_perfil: 0, nom_perfil: '', nombre_usuario: this.jwt.getUser() };
      this.servicios.mantenedorSeguridad(body, 'perfil').subscribe(res => {
        this.lt_perfil = []
        if (res.estado === 'OK') {
          if (res.resultado.length > 0) {
            var algo: any = {id_perfil: -1, nom_perfil: "-- Seleccione perfil --"};
            this.lt_perfil.push(algo,);
            res.resultado.forEach((val: any) => {
              this.lt_perfil.push(val);
            });
            this.permisoForm.get('id_perfil')?.setValue(-1)
          } else { var algo: any = {id_perfil: -1, nom_perfil: "-- Seleccione perfil --"};
            this.lt_perfil.push(algo,); 
            this.permisoForm.get('id_perfil')?.setValue(-1);
          }
        }
      });
    }
    catch (ex) { console.log(ex); }

  }
  onChangeSistema(id_sistema: any): void {
    if (id_sistema != -1) {
      this.habilitaForm(true, id_sistema);
    } else {
      this.habilitaForm(false, -1);
      this.btnGuardar = true;
      this.permisoForm.get('id_perfil')?.setValue('')
      this.datos_grilla_acceso = [];
      this.datos_grilla_permiso = [];
    }
  }
  onChangePerfil(id_perfil: any): void {
    let id_sistema = this.bodyGPermiso.id_sistema;
    if (id_perfil > -1) {
      this.cargaAcceso_Permiso(id_sistema, id_perfil);
    } else {
      this.btnGuardar = true;
      this.datos_grilla_acceso = [];
      this.datos_grilla_permiso = [];
    }
  }
  habilitaForm(estado: boolean, id_sistema: number): void {
    switch (estado) {
      case false:
        this.bodyGPermiso.id_sistema = 0;
        this.habilita = false;
        break;
      case true:
        this.bodyGPermiso.id_sistema = id_sistema;
        this.habilita = true;
        this.cargaPerfiles(id_sistema);
        break;
    }
  }
  cargaAcceso_Permiso(id_sistema: number, id_perfil: number): void {
    try {
      let body = new GPermiso('Seleccionar', id_sistema, id_perfil, [], this.jwt.getUser());
      this.servicios.mantenedorSeguridad(body, 'permiso').subscribe(res => {
        if (res.estado === 'OK') {
          let lt_accesos = res.resultado.accesos;
          this.datos_grilla_acceso = lt_accesos;

          let lt_permisos = res.resultado.permisos;
          this.datos_grilla_permiso = lt_permisos;

          this.btnGuardar = false;
        }
      });
    }
    catch (ex) { console.log(ex);}
  }
  limpiarPermiso(): void {
    this.permisoForm.patchValue({
      id_sistema: -1,
      id_perfil: '',
      id_acceso: '',
      nom_acceso: ''
    });
    this.datos_grilla_acceso = [];
    this.datos_grilla_permiso = [];
    this.actualiza = false;
    this.habilita = false;
      this.btnGuardar = true;

    var chkAccesoSelTodos = <HTMLInputElement>document.getElementById('chk_acceso_sel_todos');
    if (chkAccesoSelTodos != null) chkAccesoSelTodos.checked = false;

    var chkPermisoSelTodos = <HTMLInputElement>document.getElementById('chk_permiso_sel_todos');
    if (chkPermisoSelTodos != null) chkPermisoSelTodos.checked = false;

    var chkPermisoSelAcciones = <HTMLInputElement>document.getElementById('chk_permiso_sel_acciones');
    if (chkPermisoSelAcciones != null) chkPermisoSelAcciones.checked = false;
  }
  mantenedorPermiso(tipo: string) {
    switch (tipo) {
      case "AGREGAR":
        this.permisoForm.controls['id_acceso'].disable();
        if (this.permisoForm.valid) {
          this.bodyGPermiso.tipo = 'Insertar';
          this.bodyGPermiso.id_perfil = this.permisoForm.get('id_perfil')?.value;
          this.bodyGPermiso.lt_permiso = this.datos_grilla_permiso;
          this.servicios.mantenedorSeguridad(this.bodyGPermiso, 'permiso').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Permiso ingresado","");
              this.limpiarPermiso();
            } else {
              this.sweetAlert.sweetErr("No se pudo agregar","");
            }
          });
        }
        break;
    }
  }
  btnEditar(e: any) {
    this.actualiza = true;
    this.permisoForm.patchValue({
      id_acceso: e.rowData.id_acceso,
      nom_acceso: e.rowData.nom_acceso,
    });
  }
  onChecked(e: any) {
    switch (e.target.id) {
      case "chk_acceso_sel_todos":
        if (e.target.checked) {
          let lt_accesos = this.datos_grilla_acceso;
          for (let i = 0; i < lt_accesos.length; i++) {
            lt_accesos[i].sel = true;
          }
          this.datos_grilla_acceso = [];
          this.datos_grilla_acceso = lt_accesos;
        } else {
          let lt_accesos = this.datos_grilla_acceso;
          for (let i = 0; i < lt_accesos.length; i++) {
            lt_accesos[i].sel = false;
          }
          this.datos_grilla_acceso = [];
          this.datos_grilla_acceso = lt_accesos;
        }
        this.gridAcceso.redrawRows();
        break;
      case "chk_permiso_sel_todos":
        if (e.target.checked) {
          let lt_permisos = this.datos_grilla_permiso;
          for (let i = 0; i < lt_permisos.length; i++) {
            lt_permisos[i].sel = true;
          }
          this.datos_grilla_permiso = [];
          this.datos_grilla_permiso = lt_permisos;
        } else {
          let lt_permisos = this.datos_grilla_permiso;
          for (let i = 0; i < lt_permisos.length; i++) {
            lt_permisos[i].sel = false;
          }
          this.datos_grilla_permiso = [];
          this.datos_grilla_permiso = lt_permisos;
        }
        this.gridPermiso.redrawRows();
        break;
      case "chk_permiso_sel_acciones":
        if (e.target.checked) {
          let lt_permisos = this.datos_grilla_permiso;
          for (let i = 0; i < lt_permisos.length; i++) {
            lt_permisos[i].insertar = true;
            lt_permisos[i].seleccionar = true;
            lt_permisos[i].actualizar = true;
            lt_permisos[i].eliminar = true;
          }
          this.datos_grilla_permiso = [];
          this.datos_grilla_permiso = lt_permisos;
        } else {
          let lt_permisos = this.datos_grilla_permiso;
          for (let i = 0; i < lt_permisos.length; i++) {
            lt_permisos[i].insertar = false;
            lt_permisos[i].seleccionar = false;
            lt_permisos[i].actualizar = false;
            lt_permisos[i].eliminar = false;
          }
          this.datos_grilla_permiso = [];
          this.datos_grilla_permiso = lt_permisos;
        }
        this.gridPermiso.redrawRows();
        break;
    }

  }
  agregarAcceso(): void {
    try {
      let lt_accesos = this.datos_grilla_acceso;
      let lt_permisos = this.datos_grilla_permiso;
      let isSelected = false;
      for (let i = 0; i < lt_accesos.length; i++) {
        if (lt_accesos[i].sel == true) {
          lt_permisos.push({
            "sel": false,
            "id_sistema": parseInt(this.permisoForm.get('id_sistema')?.value),
            "id_perfil": parseInt(this.permisoForm.get('id_perfil')?.value),
            "id_acceso": lt_accesos[i].id_acceso,
            "nom_acceso": lt_accesos[i].nom_acceso,
            "seleccionar": false,
            "insertar": false,
            "eliminar": false,
            "actualizar": false,
            "nombre_usuario": null,
            "fecha_hora": "0001-01-01T00:00:00"
          });
          lt_accesos.splice(i, 1);
          i--;
          isSelected = true;
        }
      }

      if (isSelected) {
        this.datos_grilla_acceso = lt_accesos;
        this.datos_grilla_permiso = lt_permisos;
        this.gridAcceso.setRowData(this.datos_grilla_acceso);
        this.gridPermiso.setRowData(this.datos_grilla_permiso);
        
      }else{
        this.sweetAlert.sweetWarning("Para agregar","debe seleccionar al menos 1 Acceso")
      }
    } catch (ex) { console.log(ex);}
  }
  quitarAcceso(): void {
    try {
      let lt_accesos = this.datos_grilla_acceso;
      let lt_permisos = this.datos_grilla_permiso;
      let isSelected = false;
      for (let i = 0; i < lt_permisos.length; i++) {
        if (lt_permisos[i].sel == true) {
          lt_accesos.push({
            "sel": false,
            "id_sistema": parseInt(this.permisoForm.get('id_sistema')?.value),
            "id_acceso": lt_permisos[i].id_acceso,
            "nom_acceso": lt_permisos[i].nom_acceso,
            "path_acceso": null,
            "icono_acceso": null,
            "nombre_usuario": null,
            "fecha_hora": "0001-01-01T00:00:00"
          });
          lt_permisos.splice(i, 1);
          i--;
          isSelected = true;
        }
      }
      if (isSelected) {
        this.datos_grilla_acceso = lt_accesos;
        this.datos_grilla_permiso = lt_permisos;
        this.gridAcceso.setRowData(this.datos_grilla_acceso);
        this.gridPermiso.setRowData(this.datos_grilla_permiso);
        
      }else{
        this.sweetAlert.sweetWarning("Para quitar","debe seleccionar al menos 1 Acceso")
      }
    } catch (ex) { console.log(ex);}
  }

  /////////////////////////////
  public findInvalidControls() {
    const invalid = [];
    const controls = this.permisoForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  public isForceRefreshSelected() {
    return (document.querySelector('#forceRefresh') as HTMLInputElement).checked;
  }
  public isSuppressFlashSelected() {
    return (document.querySelector('#suppressFlash') as HTMLInputElement).checked;
  }
}
