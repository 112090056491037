import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  jwtToken: any;
  decodedToken: any;

  constructor() { }

  setToken(token: any) {
    if (token) {
      this.jwtToken = token;
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
    }
  }

  getDecodeToken() {
    return jwt_decode(this.jwtToken);
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.nameid : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): any {
    const expiryTime: number = this.getExpiryTime();
    if (expiryTime) {
      if (Date.now() >= expiryTime * 1000) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  }

  removeToken(){
    sessionStorage.removeItem('session_token');
  }
}
