<div class="container">
    <div class="col-5 mt-sm-4 mt-3 mb-sm-3 mb-2">
        <h3>Mantenedor Permiso</h3>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col">
            <div class="card-principal">
                <form id="formAcceso" class="contact-form" [formGroup]="permisoForm">
                    <div class="container mt-sm-4">
                        <div class="row">
                            <div class="col">

                            </div>
                        </div>
                        <div class="spacer-20"></div>
                        <div class="row">
                            <div class="col">
                                <div class="form-floating">
                                    <select id="cbSistema" formControlName="id_sistema" class="form-select"
                                        placeholder="Seleccione Sistema" #s (change)="onChangeSistema(s.value)">
                                        <option *ngFor="let sistema of lt_sistema" [value]="sistema.id_sistema">
                                            {{sistema.nom_sistema}}
                                        </option>
                                    </select>
                                    <label for="cbSistema">Sistema</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-floating">
                                    <select id="cbPerfil" formControlName="id_perfil" class="form-select" [attr.disabled]="!habilita ? '' : null"
                                        placeholder="Seleccione Perfil" #p (change)="onChangePerfil(p.value)">
                                        <option *ngFor="let perfil of lt_perfil" [value]="perfil.id_perfil">
                                            {{perfil.nom_perfil}}
                                        </option>
                                    </select>
                                    <label for="cbPerfil">Perfil</label>
                                </div>
                            </div>
                            <!-- <div *ngIf="sistemForm.controls['sistema'].invalid && (sistemForm.controls['sistema'].dirty || sistemForm.controls['sistema'].touched)">
                                <div class="help-block with-errors text-start ps-1" style="color: red; font-size: 12px; ">
                                    No puede ser vació, contener espacios ni números (_ soportado) minlength 4 maxlength 30.
                                </div>
                            </div> -->
                        </div>
                        
                        
                        
                        <div class="mb-5"></div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="d-flex justify-content-center">
                                    <p class="fs-4">Accesos disponibles</p>
                                </div>
                                <div class="card-table">
                                    <ag-grid-angular style="height: 300px;" class="ag-theme-alpine"
                                        [rowData]="datos_grilla_acceso" [columnDefs]="columna_grilla_acceso"
                                        [frameworkComponents]="frameworkComponents"
                                        (onFirstDataRendered)="onFirstDataRendered($event)" 
                                        (gridReady)="onGridAccesoReady($event)"
                                        [rowSelection]="rowSelection">
                                    </ag-grid-angular>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="form-check ms-3">
                                    <input class="form-check-input" type="checkbox" value="" id="chk_acceso_sel_todos" (change)="onChecked($event)">
                                    <label class="form-check-label" for="chk_acceso_sel_todos">
                                        Seleccionar todos
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="d-flex justify-content-center">
                                    <p class="fs-4"><==></p>
                                </div>
                                <div class="centrar mt-4">
                                    <button type="submit" class="btn btn-info" style="color: rgb(255, 255, 255);" (click)="agregarAcceso()"><i
                                            class="fas fa-angle-double-right fa-2x"></i></button>
                                    <div class="spacer-20"></div>
                                    <button type="submit" class="btn btn-danger" (click)="quitarAcceso()"><i
                                            class="fas fa-angle-double-left fa-2x"></i></button>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="d-flex justify-content-center">
                                    <p class="fs-4">Permisos para accesos</p>
                                </div>
                                <div class="card-table">
                                    <ag-grid-angular style="height: 300px;" class="ag-theme-alpine"
                                        [rowData]="datos_grilla_permiso" [columnDefs]="columna_grilla_permiso"
                                        [frameworkComponents]="frameworkComponents"
                                        (onFirstDataRendered)="onFirstDataRendered($event)"
                                        (gridReady)="onGridPermisoReady($event)"
                                        [rowSelection]="rowSelection">
                                    </ag-grid-angular>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="d-flex justify-content-between">
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="checkbox" value="" id="chk_permiso_sel_todos" (change)="onChecked($event)">
                                        <label class="form-check-label" for="chk_permiso_sel_todos">
                                            Seleccionar todos
                                        </label>
                                    </div>
                                    <div class="form-check me-3">
                                        <input class="form-check-input" type="checkbox" value="" id="chk_permiso_sel_acciones" (change)="onChecked($event)">
                                        <label class="form-check-label" for="chk_permiso_sel_acciones">
                                            Seleccionar acciones
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center border-top mb-3 mt-4"></div>
                        <div class="spacer-20"></div>
                        <div class="d-flex justify-content-end">
                            <div *ngIf="!actualiza" class="col-md-3 d-grid gap-2">
                                <button type="submit" class="btn btn-success btn-lg" [disabled]="btnGuardar" (click)="mantenedorPermiso('AGREGAR')">
                                    <i class="fas fa-plus-circle"></i>
                                    Guardar cambios</button>
                            </div>
                            <div class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-warning btn-lg" (click)="limpiarPermiso()"><i
                                        class="fas fa-exclamation-circle"></i>
                                    Cancelar</button>
                            </div>
                        </div>
                        <div class="spacer-20"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>