import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwIfEmpty } from 'rxjs';
import { JwtService } from 'src/app/services/jwt.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loggin_session: any;
  opened: boolean = true
  mode: any = 'push';

  constructor(private route: ActivatedRoute,private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {}

  ngOnInit(): void {
    /* this.router.navigate(['/home/inicio'], { relativeTo: this.route }); */
  }

  exit(){
    this.jwt.removeToken();
    this.router.navigate(['/']);
    this.sweetAlert.sweetOK("Sesión Cerrada","La sesión fue cerrada correctamente");
  }

  cambioContrasena(){
    this.router.navigate(['/home/cambio-contrasena']);
  }
}
