<div class="container">
    <div class="col-5 mt-sm-4 mt-3 mb-sm-3 mb-2">
        <h3>Panel Log</h3>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col">
            <div class="card-principal">
                <form id="formLog" class="contact-form" [formGroup]="logForm">
                    <div class="container mt-sm-4">
                        <div class="row">
                            <div class="col">
                                <div class="form-floating">
                                    <input type="text" formControlName="id_session"
                                        class="form-control text-uppercase form-control-sm" id="txtIdSession"
                                        placeholder="" autocomplete="off" maxlength="30" minlength="1"
                                        required>
                                    <label for="txtIdSession">Id Session</label>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-20"></div>
                        <div class="d-flex justify-content-left">
                            <div class="col-md-3 d-grid gap-2">
                                <button type="submit" class="btn btn-primary" (click)="buscarLog()"
                                    [disabled]="logForm.pristine || logForm.invalid">
                                    <i class="fas fa-plus-circle"></i>
                                    Buscar
                                </button>
                            </div>

                            <div class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-warning" (click)="limpiarLog()">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Cancelar</button>
                            </div>
                        </div>
                        <div class="text-center border-top mb-3 mt-4"></div>
                        <h4 class="mb-3">Log</h4>
                        <div class="row mt-sm-4 mt-2 mb-sm-2 ps-sm-3 pe-sm-3">
                            <div class="col-sm card-table">
                                <ag-grid-angular style="height: 300px;" class="ag-theme-alpine" [rowData]="datosGrilla"
                                    [columnDefs]="columnas_grilla" (rowDataChanged)="onFirstDataRendered($event)">
                                </ag-grid-angular>
                            </div>
                        </div>
                        <div class="text-center border-top mb-3 mt-4"></div>
                        <h4 class="mb-3">Servicios</h4>
                        <div class="row mt-sm-4 mt-2 mb-sm-2 ps-sm-3 pe-sm-3">
                            <div class="col-sm card-table">
                                <ag-grid-angular style="height: 300px;" class="ag-theme-alpine"
                                    [rowData]="datosGrillaServi" [columnDefs]="columnas_grillaServi" (rowDataChanged)="onFirstDataRendered($event)">
                                </ag-grid-angular>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>