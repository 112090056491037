import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkbox-grilla',
  template: `<input type="checkbox" (click)="checkedHandler($event)" [checked]="params.value" class=""/>`
})

export class CheckboxGrillaComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event: any) {
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  refresh(params?: any): boolean {
    return true;
  }
}