import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt.service';
import { Router } from '@angular/router';
import { Sweetalert2Service } from '../services/sweet-alert.service';

@Injectable()
export class IsLoggedInterceptor implements HttpInterceptor {

  constructor(private jwt: JwtService, private router: Router, private sweetAlert: Sweetalert2Service) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    try {
    let session = sessionStorage.getItem('session_token');
    if (session !== undefined && session !== null) {
      if (this.jwt.isTokenExpired()) {  // despues de 15 min token expira, si es así entra acá
        this.jwt.removeToken();
        this.router.navigate(['/']);
        this.sweetAlert.sweetWarning("Token Expirado","Por tu segúridad debes iniciar nuevamente sesión");
      }else{
        //token sigue vigente, no se realiza nunguna acción
      }
    }else{
      this.jwt.removeToken();
      this.router.navigate(['/']);
    }
    } catch (error) {
      console.log('token error', error);
      
    }
    return next.handle(request);
  }
}
