<div class="container">
    <div class="col-5 mt-sm-4 mt-3 mb-sm-3 mb-2">
        <h3>Mantenedor Usuario</h3>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col">
            <div class="card-principal">
                <form id="formPerfil" class="contact-form" [formGroup]="usuarioForm">
                    <div class="container mt-sm-4">
                        <div class="spacer-20"></div>
                        <div class="row">
                            <div class="col">
                                <div class="form-floating input-group">
                                    <input type="text" formControlName="rut_usuario" (input)="onRutInput($event)" tabindex="1"
                                            class="form-control text-uppercase" id="txtRutUsuario" autocomplete="off" minlength="11" maxlength="12" required>
                                        <label for="txtRutUsuario">Rut Usuario</label>
                                    <button class="btn btn-outline-secondary" type="button" 
                                        (click)="buscarUsuario('rut')">Buscar</button>
                                </div>
                                <div>
                                    <div *ngIf="usuarioForm.get('rut_usuario')?.hasError('required') && usuarioForm.get('rut_usuario')?.touched">
                                        <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">campo requerido</p> 
                                    </div>
                                    <div *ngIf="usuarioForm.get('rut_usuario')?.hasError('rutInvalido')">
                                        <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">rut no válido</p> 
                                    </div>
                                    <div *ngIf="usuarioForm.get('rut_usuario')?.hasError('minlength') || usuarioForm.get('rut_usuario')?.hasError('maxlength')">
                                        <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">minimo 11, máximo 12 caracteres</p> 
                                    </div>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="form-floating">
                                    <input type="text"  class="form-control text-uppercase" formControlName="nombre" maxlength="30" minlength="3"
                                        id="txtNombre" autocomplete="off" tabindex="3" required>
                                        <div *ngIf="usuarioForm.get('nombre')?.hasError('pattern')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">solo se permiten letras de la A a la Z</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('nombre')?.hasError('required') && usuarioForm.get('nombre')?.touched">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">campo requerido</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('nombre')?.hasError('minlength') || usuarioForm.get('nombre')?.hasError('maxlength')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">minimo 3, máximo 30 caracteres</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('nombre')?.hasError('spaces')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">se permiten como máximo dos espacios entre caracteres o palabras</p> 
                                        </div>
                                    <label for="txtNombre">Nombre</label>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="form-floating">
                                    <input type="text" class="form-control text-uppercase" formControlName="ape_materno" maxlength="30" minlength="3"
                                        id="txtApeMaterno" autocomplete="off" tabindex="5" required>
                                        <div *ngIf="usuarioForm.get('ape_materno')?.hasError('pattern')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">solo se permiten letras de la A a la Z</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('ape_materno')?.hasError('required') && usuarioForm.get('ape_materno')?.touched">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">campo requerido</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('ape_materno')?.hasError('minlength') || usuarioForm.get('ape_materno')?.hasError('maxlength')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">minimo 3, máximo 30 caracteres</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('ape_materno')?.hasError('spaces')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">Se permiten como máximo dos espacios entre caracteres o palabras</p> 
                                        </div>
                                    <label for="txtApeMaterno">Apellido Materno</label>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="form-floating">
                                    <div class="form-check form-switch form-switch-lg">
                                      <input id="chkFuncionario" class="form-check-input" type="checkbox" formControlName="funcionario" tabindex="7">
                                      <label class="form-check-label ms-3 mt-2" for="chkFuncionario"> Funcionario</label>
                                    </div>
                                  </div>
                                  
                                <!-- <div class="form-floating">
                                    <div class="form-check" style="padding-top: 0px;">
                                        <input id="chkFuncionario" type="checkbox" formControlName="funcionario"
                                            class="form-check-input" value="">
                                        <label class="form-check-label" for="chkFuncionario">
                                            Funcionario
                                        </label>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col">
                                <div class="form-floating input-group">
                                    <input type="text" class="form-control" formControlName="id_usuario" maxlength="40" minlength="4"
                                        id="txtIdUsuario" autocomplete="off" tabindex="2" required>
                                    <label for="txtIdUsuario">ID Usuario</label>
                                    <button class="btn btn-outline-secondary" type="button" 
                                        (click)="buscarUsuario('usuario')">Buscar</button>
                                </div>
                                <div>
                                    <div *ngIf="usuarioForm.get('id_usuario')?.hasError('required') && usuarioForm.get('id_usuario')?.touched">
                                        <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">campo requerido</p> 
                                    </div>
                                    <div *ngIf="usuarioForm.get('id_usuario')?.hasError('minlength') || usuarioForm.get('id_usuario')?.hasError('maxlength')">
                                        <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">minimo 4, máximo 40 caracteres</p> 
                                    </div>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="form-floating">
                                    <input type="text" class="form-control text-uppercase" formControlName="ape_paterno" tabindex="4"
                                        id="txtApePaterno" autocomplete="off" required>
                                        <div *ngIf="usuarioForm.get('ape_paterno')?.hasError('pattern')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">solo se permiten letras de la A a la Z</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('ape_paterno')?.hasError('required') && usuarioForm.get('ape_paterno')?.touched">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">campo requerido</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('ape_paterno')?.hasError('minlength') || usuarioForm.get('ape_paterno')?.hasError('maxlength')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">minimo 3, máximo 30 caracteres</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('ape_paterno')?.hasError('spaces')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">Se permiten como máximo dos espacios entre caracteres o palabras</p> 
                                        </div>
                                    <label for="txtApePaterno">Apellido Paterno</label>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="email" tabindex="6"
                                        id="txtEmail" autocomplete="off" required>
                                        <div *ngIf="usuarioForm.get('email')?.hasError('required') && usuarioForm.get('email')?.touched">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">campo requerido</p> 
                                        </div>
                                        <div *ngIf="usuarioForm.get('email')?.hasError('pattern')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">email no valido</p> 
                                        </div>
                                    <label for="txtEmail">Email</label>
                                </div>
                            </div>
                            <!-- <div *ngIf="sistemForm.controls['sistema'].invalid && (sistemForm.controls['sistema'].dirty || sistemForm.controls['sistema'].touched)">
                                <div class="help-block with-errors text-start ps-1" style="color: red; font-size: 12px; ">
                                    No puede ser vació, contener espacios ni números (_ soportado) minlength 4 maxlength 30.
                                </div>
                            </div> -->
                        </div>
                        <div class="spacer-20"></div>
                        <div class="d-flex justify-content-left">
                            <div *ngIf="!actualiza" class="col-md-3 d-grid gap-2">
                                <button type="submit" class="btn btn-success btn-lg" [disabled]="btnAgrega" (click)="mantenedorUsuario('AGREGAR')"><i
                                        class="fas fa-plus-circle" tabindex="8"></i>
                                    Agregar</button>
                            </div>
                            <div *ngIf="actualiza" class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-success btn-lg"
                                    (click)="mantenedorUsuario('ACTUALIZAR')"><i class="fas fa-plus-circle"></i>
                                    Actualizar</button>
                            </div>
                            <div *ngIf="actualiza" class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-danger btn-lg" (click)="mantenedorUsuario('ELIMINAR')"><i
                                        class="fas fa-minus-circle"></i>
                                    Eliminar</button>
                            </div>
                            <div class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-warning btn-lg" (click)="limpiarUsuario()"><i
                                        class="fas fa-exclamation-circle"></i>
                                    Cancelar</button>
                            </div>
                        </div>
                        <div class="text-center border-top mb-3 mt-4"></div>
                        <h4 class="mb-3">Registros</h4>
                        <div class="row mt-sm-4 mt-2 mb-sm-2 ps-sm-3 pe-sm-3">
                            <div class="col-sm card-table">
                                <ag-grid-angular style="height: 300px;" class="ag-theme-alpine" [rowData]="datosGrilla"
                                    [columnDefs]="columnas_grilla" [frameworkComponents]="frameworkComponents"
                                    (firstDataRendered)="onFirstDataRendered($event)">
                                </ag-grid-angular>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>