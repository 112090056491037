import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/services/service.service';
import { Sistema } from 'src/app/models/class/sistema.class';
import { JwtService } from 'src/app/services/jwt.service';
import { ColDef } from 'ag-grid-community';
import { BotonGrillaComponent } from '../componentes-grilla/boton-grilla/boton-grilla.component';
import { Router } from '@angular/router';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';

@Component({
  selector: 'app-sistema',
  templateUrl: './sistema.component.html',
  styleUrls: ['./sistema.component.scss']
})
export class SistemaComponent implements OnInit {
  bodySistema = new Sistema('', 0, '', '');
  actualiza: boolean = false;
  frameworkComponents: any;
  columnas_grilla: ColDef[] = [
    { field: 'id_sistema', headerName: "ID Sistema" },
    { field: 'nom_sistema', headerName: "Nombre Sistema" },
    {
      field: '',
      cellRenderer: 'botonRender',
      cellRendererParams: {
        onClick: this.btnEditar.bind(this),
        label: 'Editar'
      },
      cellStyle: { textAlign: "center" }
    }
  ];
  public defaultColDef: ColDef = { //ancho minimo de cada columna en el grid
    minWidth:393,
  };
  datosGrilla = [];
  sistemaForm: UntypedFormGroup = new UntypedFormGroup({
    tipo: new UntypedFormControl(),
    id_sistema: new UntypedFormControl(),
    nom_sistema: new UntypedFormControl('', [Validators.minLength(4), Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]*$'), this.customSpacesValidator(3)]),
    nombre_usuario: new UntypedFormControl()
  });
  mostrar_rsa: boolean = false;
  code: any = null;
  code_rsa: any = `id_sistema:\t\t\t@ID_SISTEMA@\nnom_sistema:\t\t@NOM_SISTEMA@\ntoken_sistema:\t\t@TOKEN_SISTEMA@\npublic_key:\t\t\t@PUBLIC_KEY@\nprivate_key:\t\t@PRIVATE_KEY@`;

  constructor(private servicios: ServiceService, private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {
    if (!this.jwt.isTokenExpired()) {
      this.frameworkComponents = {
        botonRender: BotonGrillaComponent,
      }
      this.bodySistema.nombre_usuario = this.jwt.getUser();
    }
  }

  ngOnInit(): void {
    this.cargaSistemas();
    this.sistemaForm.controls['id_sistema'].disable();
  }
  onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }

  cargaSistemas(): void {
    try {
      let body = new Sistema('Seleccionar', 0, '', this.jwt.getUser());
      this.servicios.mantenedorSeguridad(body, 'sistema').subscribe(res => {
        if (res.estado === 'OK') {
          this.datosGrilla = res.resultado;
        }
      });
    }
    catch (ex) { console.log(ex); }
  }
  limpiarSistema(): void {
    this.sistemaForm.patchValue({
      id_sistema: '',
      nom_sistema: '',
    });
    this.sistemaForm.controls['id_sistema'].enable();
    this.sistemaForm.controls['nom_sistema'].enable();
    this.bodySistema.id_sistema = 0;
    this.bodySistema.nom_sistema = '';
    this.actualiza = false;
    this.mostrar_rsa = false;
    this.code = null;
    this.sistemaForm.get('nom_sistema')?.markAsUntouched();
  }
  mantenedorSistema(tipo: string) {
    try {
      switch (tipo) {
        case "ELIMINAR":
          if (confirm("¿Esta seguro que desea Eliminar?")) {
            if (this.sistemaForm.valid) {
              this.bodySistema.tipo = 'Eliminar';
              this.bodySistema.id_sistema = this.sistemaForm.get('id_sistema')?.value;
              this.servicios.mantenedorSeguridad(this.bodySistema, 'sistema').subscribe(res => {
                if (res.estado === 'OK') {
                  this.sweetAlert.sweetOK("Sistema eliminado","");
                  this.limpiarSistema();
                  this.cargaSistemas();
                } else {
                }
              });
            }
          } else { this.sweetAlert.sweetWarning("No se pudo realizar la acción eliminar",""); }
          break;
        case "AGREGAR":
          this.sistemaForm.controls['id_sistema'].disable();
          if (this.sistemaForm.valid) {
            this.bodySistema.tipo = 'Insertar';
            this.bodySistema.nom_sistema = this.sistemaForm.get('nom_sistema')?.value.toUpperCase();
            this.servicios.mantenedorSeguridad(this.bodySistema, 'sistema').subscribe(res => {
              if (res.estado === 'OK') {
                this.sweetAlert.sweetOK("Sistema ingresado","");
                this.cargaLlavesRSA(res);
                this.sweetAlert.sweetOK("LLAVES RSA CREADAS","SE ENCUENTRAN COPIADAS EN TU PORTAPAPELES, ES IMPORTANTE PEGARLAS EN UN BLOCK DE NOTAS Y GUARDARLAS PARA USOS FUTUROS.");
                this.limpiarSistema();
                this.cargaSistemas();
                this.mostrar_rsa = true;
              } else {
                this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());}
            });
          }else{this.sweetAlert.sweetWarning("No se pudo realizar la acción","Ingrese : Nombre sistema");}
          break;
        case "ACTUALIZAR":
          if (this.sistemaForm.valid) {
            this.bodySistema.tipo = 'Actualizar';
            this.bodySistema.id_sistema = this.sistemaForm.get('id_sistema')?.value;
            this.bodySistema.nom_sistema = this.sistemaForm.get('nom_sistema')?.value.toUpperCase();
            this.servicios.mantenedorSeguridad(this.bodySistema, 'sistema').subscribe(res => {
              if (res.estado === 'OK') {
                this.sweetAlert.sweetOK("Sistema actualizado","");
                this.limpiarSistema();
                this.cargaSistemas();
              } else {this.sweetAlert.sweetWarning("No se pudo realizar la acción actualizar","");}
            });
          } else {
            this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());}
          break;
      }
    } catch (ex) { console.log(ex);}
  }
  cargaLlavesRSA(res: any): void {
    try {
      if (res != null) {
        var code_rsa_ = this.code_rsa;
        code_rsa_ = code_rsa_.replace('@ID_SISTEMA@', res.resultado.id_sistema);
        code_rsa_ = code_rsa_.replace('@NOM_SISTEMA@', res.resultado.nom_sistema);
        code_rsa_ = code_rsa_.replace('@TOKEN_SISTEMA@', res.resultado.token_sistema);
        code_rsa_ = code_rsa_.replace('@PUBLIC_KEY@', res.resultado.llave_publica);
        code_rsa_ = code_rsa_.replace('@PRIVATE_KEY@', res.resultado.llave_privada);
        this.code = code_rsa_;
        this.textToClipboard();
      }
    } catch (ex) { console.log('CATCH: ' + ex); }
  }
  textToClipboard() {
    var text = this.code;
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
  btnEditar(e: any) {
    this.actualiza = true;
    this.sistemaForm.patchValue({
      id_sistema: e.rowData.id_sistema,
      nom_sistema: e.rowData.nom_sistema,
    });
    this.actualiza = true;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.sistemaForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(" "+name);
      }
    }
    return invalid;
  }

  customSpacesValidator(maxSpaces: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const spaces = control.value.split(' ').length - 1;
      if (spaces > maxSpaces) {
        return { spaces: true };
      }
      return null;
    };
  }

}
