<div class="container">
    <div class="row">
        <div class="col mt-5">
            <br><br><br><br>
            <div class="wrapper">
                <form id="formLogin" class="contact-form" [formGroup]="cambioContrasenaForm" (ngSubmit)="cambiarContrasena()">
                  <h5>Cambia tu contraseña</h5>
                        <hr class="sep" />
                        <div class="group">
                            <input id="pass" formControlName="contrasena" type="password" maxlength="30"
                                [type]="fieldTextType ? 'text' : 'password'"
                                required />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>Ingresa tu nueva contraseña</label>
                            <span class="left-icon" (click)="muestraPass()">
                                <i class="fa enlace"
                                    [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}">
                                </i>
                            </span>
                        </div>
                        <div class="group">
                            <input id="pasS" formControlName="contrasenaNueva" type="password" maxlength="30"
                                [type]="fieldTextType ? 'text' : 'password'"
                                required />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>Confirma tu nueva contraseña</label>
                            <span class="left-icon" (click)="muestraPass()">
                                <i class="fa enlace"
                                    [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}">
                                </i>
                            </span>
                        </div>
                        <div *ngIf="cambioContrasenaForm.controls['contrasenaNueva'].invalid && (cambioContrasenaForm.controls['contrasenaNueva'].dirty || cambioContrasenaForm.controls['contrasenaNueva'].touched)">
                            <div class="help-block with-errors" style="color: rgb(227, 37, 107); font-size: 12px;">* Mínimo ocho caracteres, al menos una letra mayúscula, una letra minúscula, 
                                un número y un caracter especial ($@!¡#%*ñ()+¿?&).
                            </div>
                        </div>
                        <div class="container btn-box">
                          <div class="d-flex justify-content-start">
                              <button type="button" class="btn btn-secondary me-auto" (click)="limpiar()">Limpiar</button>
                              <button type="submit" class="btn btn-submit me-2" [disabled]="!cambioContrasenaForm.valid">Aplicar</button>
                          </div>
                      </div>
                      </form>
              </div>
        </div>
        
    </div>
    </div>

