<div class="container">
    <div class="col-5 mt-sm-4 mt-3 mb-sm-3 mb-2">
        <h3>Mantenedor Sistema</h3>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col">
            <div class="card-principal">
                <form id="formAcceso" class="contact-form" [formGroup]="sistemaForm">
                    <div class="container mt-sm-4">
                        <div class="row">
                            <div class="col">
                                <fieldset disabled>
                                <div class="form-floating">
                                    <input type="text" formControlName="id_sistema"
                                        class="form-control text-uppercase form-control-sm" id="txtIdSistema" 
                                        placeholder="" autocomplete="off" maxlength="30" minlength="4" readonly
                                        required>
                                    <label for="txtIdSistema">Id Sistema</label>
                                </div>
                                </fieldset>
                            </div>
                            <div class="col">
                                <div class="form-floating">
                                    <input type="text" formControlName="nom_sistema"
                                        class="form-control form-control-sm text-uppercase" id="txtNomSistema"
                                        placeholder="" autocomplete="off" maxlength="30" minlength="4" required>
                                        <div *ngIf="sistemaForm.get('nom_sistema')?.hasError('pattern')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">solo se permiten letras de la A a la Z</p> 
                                        </div>
                                        <div *ngIf="sistemaForm.get('nom_sistema')?.hasError('required') && sistemaForm.get('nom_sistema')?.touched">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">campo requerido</p> 
                                        </div>
                                        <div *ngIf="sistemaForm.get('nom_sistema')?.hasError('minlength') || sistemaForm.get('nom_sistema')?.hasError('maxlength')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">minimo 4, máximo 30 caracteres</p> 
                                        </div>
                                        <div *ngIf="sistemaForm.get('nom_sistema')?.hasError('spaces')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">Se permiten como máximo tres espacios entre caracteres o palabras</p> 
                                        </div>
                                    <label for="txtNomSistema">Nombre Sistema</label>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-20"></div>
                        <div class="d-flex justify-content-left">
                            <div *ngIf="!actualiza" class="col-md-3 d-grid gap-2">
                                <button type="submit" class="btn btn-success btn-lg form-control" (click)="mantenedorSistema('AGREGAR')"><i
                                        class="fas fa-plus-circle"></i>
                                    Agregar</button>
                            </div>
                            <div *ngIf="actualiza" class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-success btn-lg"
                                    (click)="mantenedorSistema('ACTUALIZAR')"><i class="fas fa-plus-circle"></i>
                                    Actualizar</button>
                            </div>
                            <div *ngIf="actualiza" class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-danger btn-lg" (click)="mantenedorSistema('ELIMINAR')"><i
                                        class="fas fa-minus-circle"></i>
                                    Eliminar</button>
                            </div>
                            <div class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-warning btn-lg" (click)="limpiarSistema()"><i
                                        class="fas fa-exclamation-circle"></i>
                                    Cancelar</button>
                            </div>
                        </div>
                        <div class="text-center border-top mb-3 mt-4"></div>
                        <h4 class="mb-3">Registros</h4>
                        <div class="row mt-sm-4 mt-2 mb-sm-2 ps-sm-3 pe-sm-3">
                            <div class="col-sm card-table">
                                <ag-grid-angular style="height: 300px;" class="ag-theme-alpine" [rowData]="datosGrilla"
                                    [columnDefs]="columnas_grilla" [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
                                    (rowDataChanged)="onFirstDataRendered($event)">
                                </ag-grid-angular>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>