import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Log } from '../models/class/log.class';
import { CambiaContraseña } from '../models/interface/cambiaContrasena.interface';
import { Encripta } from '../models/interface/encripta.interface';
import { Login } from '../models/interface/login.interface';
import { RecuperaContraseña } from '../models/interface/recuperaContrasena.interface';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }

  encripta(body: Encripta): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token-Sistema': environment.token_sistema
    });
    let options = { headers: headers };
    return this.http.post(environment.url_api_ms + 'seguridad/encripta', body, options);
  }

  login(body: Login): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token-Sistema': environment.token_sistema
    });
    let options = { headers: headers };
    return this.http.post(environment.url_api_ms + 'login/auth', body, options);
  } 

  serviciosSeguridad (servicio: any, body: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token-Sistema': environment.token_sistema
    });
    let options = { headers: headers };
    return this.http.post(environment.url_api_ms + 'seguridad/'+ servicio, body, options);
  }

  mantenedorSeguridad(body: any, servicio: string): Observable<any> {
    let jwt = sessionStorage.getItem('session_token');
    if (jwt != null) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Token-Sistema': environment.token_sistema,
        'Authorization': 'AGP_ ' + jwt
      });
      let options = { headers: headers };
      return this.http.post(environment.url_api_ms + 'servicios/' + servicio, body, options);
    }else{
      return new Observable();
    }
  }

  getLog(id_session: number): Observable<any>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token-Sistema': environment.token_sistema
    });
    let options = { headers: headers };
    return this.http.get(environment.url_api_log + 'servicios/get-log/' + id_session, options);
  }
}
