<div *ngIf="recupera">
    <div class="container">
        <div class="row">
            <div class="col">
                <br><br><br><br>
                <div class="wrapper">
                    <form id="formLogin" class="contact-form" [formGroup]="recuperaContrasenaForm"
                        (ngSubmit)="recuperaLogin()">
                        <h1>Reestablecer contraseña</h1>
                        <h5>Módulo de seguridad</h5>
                        <hr class="sep" />
                        <div class="group">
                            <input formControlName="rut" (input)="onRutInput($event)" tabindex="1" maxlength="12" required />
                            <span class="highlight"></span><span class="bar"></span>
                            <label>Rut</label>
                        </div>
                        <div class="group">
                            <input formControlName="usuario" tabindex="2" maxlength="40" required="required" /><span
                                class="highlight"></span><span class="bar"></span>
                            <label>Usuario</label>
                        </div>
                        <div class="d-grid gap-2 btn-box ">
                            <button class="btn btn-submit" type="submit"
                                [disabled]="!recuperaContrasenaForm.valid">Reestablecer</button>
                        </div>

                        <div class="text-end">
                            <span class="ml-auto">
                                <a (click)="navega()" class="forgot-pass text-muted enlace-como-span pe-3"
                                    style="font-size:14px;">Iniciar sesión</a>
                            </span>
                        </div>
                        <form id="formCodigo" class="contact-form" [formGroup]="codigoVerificaForm"
                            (ngSubmit)="codigoVerifica()">
                            <div class="modal fade" id="recupera" data-bs-backdrop="static" data-bs-keyboard="false"
                                tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">¡Revisa tu correo!</h5>

                                        </div>
                                        <div class="modal-body">
                                            Hemos enviado un código a tu correo el cual debes ingresar aquí para
                                            restablecer tu contraseña.<br><br>
                                            <div class="form-floating col-5 mb-3 mx-auto" style="padding-left: 8px;">

                                                <input formControlName="codVerifica" type="txt"
                                                    class="form-control input-p" id="floatingInput"
                                                    placeholder="xxxxxxxx" maxlength="8">
                                                <label for="floatingInput">Ingresa el código</label>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <div class="container">
                                                <div class="d-flex justify-content-start">
                                                    <button *ngIf="mostrarBoton" type="button"
                                                        class="btn btn-secondary me-auto"
                                                        data-bs-dismiss="modal">Cancelar</button>
                                                    <button type="submit" class="btn btn-submit me-2"
                                                        [disabled]="!codigoVerificaForm.valid">¡Listo!</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="okRecupera">
    <div class="container">
        <div class="row">

            <div class="col">
                <br><br><br><br>
                <div class="wrapper">
                    <form id="formContrasenaCambia" class="contact-form" [formGroup]="contrasenaForm"
                        (ngSubmit)="finalizaRecupera()">
                        <!-- <h1>Cambia tu contraseña</h1> -->
                        <h5>Cambia tu contraseña</h5>
                        <hr class="sep" />
                        <div class="group">
                            <input id="pass" formControlName="nPass" type="password" minlength="8" maxlength="30"
                                [type]="fieldTextType ? 'text' : 'password'" (ngModelChange)="comparaContrasena()"
                                required />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>Ingresa tu nueva contraseña</label>
                            <span class="left-icon" (click)="muestraPass()">
                                <i class="fa enlace"
                                    [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}">
                                </i>
                            </span>
                        </div>
                        <div class="group">
                            <input id="pasS" formControlName="cPass" type="password" minlength="8" maxlength="30"
                                [type]="fieldTextType ? 'text' : 'password'" (ngModelChange)="comparaContrasena()"
                                required />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>Confirma tu nueva contraseña</label>
                            <span class="left-icon" (click)="muestraPass()">
                                <i class="fa enlace"
                                    [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}">
                                </i>
                            </span>
                        </div>
                        <div
                            *ngIf="contrasenaForm.controls['cPass'].invalid && (contrasenaForm.controls['cPass'].dirty || contrasenaForm.controls['cPass'].touched)">
                            <div class="help-block with-errors" style="color: rgb(227, 37, 107); font-size: 12px;">* Las contraseñas no son iguales</div>
                        </div>
                        <div *ngIf="contrasenaForm.controls['nPass'].invalid && (contrasenaForm.controls['nPass'].dirty || contrasenaForm.controls['nPass'].touched)">
                            <div class="help-block with-errors" style="color: rgb(227, 37, 107); font-size: 12px;">* Mínimo ocho caracteres, al menos una letra mayúscula, una letra minúscula, 
                                un número y un caracter especial ($@!¡#%*ñ()+¿?&).
                            </div>
                        </div>
                        <div class="btn-box">
                            <button class="btn btn-submit" type="submit"
                                [disabled]="!contrasenaForm.valid">Aplicar</button>
                        </div>

                        <div class="modal fade" id="okRecuperaModal" tabindex="-1"
                            aria-labelledby="okRecuperaModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">¡Tu contraseña esta lista!
                                        </h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        Tu contraseña fue restablecida exitosamente. Ahora puedes acceder
                                        con tu correo y contraseña a la plataforma de Infocar.<br><br>
                                    </div>
                                    <div class="modal-footer">

                                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                            (click)="navega()">¡Gracias!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>