import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BotonGrillaComponent } from '../componentes-grilla/boton-grilla/boton-grilla.component';
import { JwtService } from 'src/app/services/jwt.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { ServiceService } from 'src/app/services/service.service';
import { Sistema } from 'src/app/models/class/sistema.class';
import { Acceso } from 'src/app/models/class/acceso.class';
import { Router } from '@angular/router';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';


@Component({
  selector: 'app-acceso',
  templateUrl: './acceso.component.html',
  styleUrls: ['./acceso.component.scss']
})
export class AccesoComponent implements OnInit {

  bodyAcceso = new Acceso('', 0, 0, '', '', '', '');
  lt_sistema: Sistema[] = [];
  actualiza: boolean = false;
  btnAgregar: boolean = true;
  frameworkComponents: any;
  columnas_grilla: ColDef[] = [
    { field: 'id_acceso', headerName: "ID Acceso" },
    { field: 'nom_acceso', headerName: "Nombre Acceso" },
    { field: 'path_acceso', headerName: "Path Acceso" },
    { field: 'icono_acceso', headerName: "Icono Acceso" },
    {
      field: '',
      cellRenderer: 'botonRender',
      cellRendererParams: {
        onClick: this.btnEditar.bind(this),
        label: 'Editar'
      },
      cellStyle: { textAlign: "center" }
    }
  ];
  public defaultColDef: ColDef = { //ancho minimo de cada columna en el grid
    minWidth:240,
  };
  datosGrilla = [];
  accesoForm: UntypedFormGroup = new UntypedFormGroup({
    tipo: new UntypedFormControl(),
    id_sistema: new UntypedFormControl('', [Validators.required]),
    id_acceso: new UntypedFormControl(),
    nom_acceso: new UntypedFormControl('', [Validators.minLength(4), Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]*$'), this.customSpacesValidator(3)]),
    path_acceso: new UntypedFormControl(),
    icono_acceso: new UntypedFormControl(),
    nombre_usuario: new UntypedFormControl()
  });
  


  constructor(private servicios: ServiceService, private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {
    if (!this.jwt.isTokenExpired()) {
      this.frameworkComponents = {
        botonRender: BotonGrillaComponent,
      }
      this.bodyAcceso.nombre_usuario = this.jwt.getUser();
    }
  }

  ngOnInit(): void {
    this.cargaSistemas();
    this.EnableOrDisableInputForm('Disable');
  }
  onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }

  cargaSistemas(): void {
    try {
      let body: Sistema = { tipo: 'Seleccionar', id_sistema: 0, nom_sistema: '', nombre_usuario: this.jwt.getUser() };
      this.servicios.mantenedorSeguridad(body, 'sistema').subscribe(res => {
        if (res.estado === 'OK') {
          if (res.resultado.length > 0) {
            var algo: any = {id_sistema: -1, nom_sistema: "-- Seleccione Sistema --"};
            this.lt_sistema.push(algo);
            res.resultado.forEach((val: any) => {
              this.lt_sistema.push(val);
            });
            this.accesoForm.get('id_sistema')?.setValue(-1)
          } else { this.lt_sistema = []; }
        }
      });
    }
    catch (ex) { console.log(ex); }
  }
  onChangeSistema(id_sistema: any): void {
    if (id_sistema != -1) {
      this.habilitaForm(true, id_sistema);
      this.btnAgregar = false;
    } else {
      this.habilitaForm(false, -1);
    }
  }
  habilitaForm(estado: boolean, id_sistema: number): void {
    switch (estado) {
      case false:
        document.getElementById('txtNomAcceso')?.setAttribute('readonly', 'true');
        document.getElementById('txtPathAcceso')?.setAttribute('readonly', 'true');
        document.getElementById('txtIconoAcceso')?.setAttribute('readonly', 'true');
        this.datosGrilla = [];
        this.bodyAcceso.id_sistema = 0;
        this.EnableOrDisableInputForm('Disable');
        break;
      case true:
        document.getElementById('txtNomAcceso')?.removeAttribute('readonly');
        document.getElementById('txtPathAcceso')?.removeAttribute('readonly');
        document.getElementById('txtIconoAcceso')?.removeAttribute('readonly');
        this.bodyAcceso.id_sistema = id_sistema;
        this.cargaAccesos(id_sistema);
        this.EnableOrDisableInputForm('Enable');
        break;
    }

  }
  cargaAccesos(id_sistema: number): void {
    try {
      let body = new Acceso('Seleccionar', id_sistema, 0, '', '', '', this.jwt.getUser());
      this.servicios.mantenedorSeguridad(body, 'acceso').subscribe(res => {
        if (res.estado === 'OK') {
          this.datosGrilla = res.resultado;
        }
      });
    }
    catch (ex) { console.log(ex); }
  }
  limpiarAcceso(): void {
    this.accesoForm.patchValue({
      id_acceso: '',
      nom_acceso: '',
      path_acceso: '',
      icono_acceso: '',
    });
    this.EnableOrDisableInputForm('Disable');
    this.accesoForm.controls['id_sistema'].enable();
    this.accesoForm.get('id_sistema')?.setValue(-1)
    this.actualiza = false;
    this.btnAgregar = true;
    this.datosGrilla = [];
  }
  mantenedorAcceso(tipo: string) {
    try {
      switch (tipo) {
        case "ELIMINAR":
          if (confirm("¿Esta seguro que desea Eliminar?")) {
            if (this.accesoForm.value.id_acceso != '') {
              this.bodyAcceso.tipo = 'Eliminar';
              this.bodyAcceso.id_acceso = this.accesoForm.value.id_acceso;
              this.servicios.mantenedorSeguridad(this.bodyAcceso, 'acceso').subscribe(res => {
                if (res.estado === 'OK') {
                  this.sweetAlert.sweetOK("Acceso eliminado","");
                  this.limpiarAcceso();
                  this.cargaAccesos(this.bodyAcceso.id_sistema);
                } else {
                  this.sweetAlert.sweetErr("No se pudo eliminar","");
                }
              });
            } else { }
          } else { }
          break;
        case "AGREGAR":
          if (this.accesoForm.valid) {
            this.accesoForm.controls['id_acceso'].disable();
            this.accesoForm.controls['path_acceso'].disable();
            this.accesoForm.controls['icono_acceso'].disable();
            this.bodyAcceso.tipo = 'Insertar';
            this.bodyAcceso.id_acceso = 0;
            this.bodyAcceso.nom_acceso = this.accesoForm.value.nom_acceso.toUpperCase();
            this.bodyAcceso.path_acceso = this.accesoForm.get('path_acceso')?.value;
            this.bodyAcceso.icono_acceso = this.accesoForm.get('icono_acceso')?.value;
            this.servicios.mantenedorSeguridad(this.bodyAcceso, 'acceso').subscribe(res => {
              if (res.estado === 'OK') {
                this.sweetAlert.sweetOK("Acceso ingresado","");
                this.limpiarAcceso();
                this.cargaAccesos(this.bodyAcceso.id_sistema);
              } else {
                this.sweetAlert.sweetErr("No se pudo ingresar","");
              }
            });
          } else {
            this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());}
          break;
        case "ACTUALIZAR":
          this.accesoForm.controls['path_acceso'].disable();
          this.accesoForm.controls['icono_acceso'].disable();
          if (this.accesoForm.valid) {
            this.bodyAcceso.tipo = 'Actualizar';
            this.bodyAcceso.id_acceso = this.accesoForm.get('id_acceso')?.value;
            this.bodyAcceso.nom_acceso = this.accesoForm.value.nom_acceso.toUpperCase();
            this.bodyAcceso.path_acceso = this.accesoForm.get('path_acceso')?.value;
            this.bodyAcceso.icono_acceso = this.accesoForm.get('icono_acceso')?.value;
            this.servicios.mantenedorSeguridad(this.bodyAcceso, 'acceso').subscribe(res => {
              if (res.estado === 'OK') {
                this.sweetAlert.sweetOK("Acceso actualizado","");
                this.cargaAccesos(this.bodyAcceso.id_sistema);
                this.limpiarAcceso();
                
                
                this.datosGrilla = [];
                console.log('salio');
              } else {
                this.sweetAlert.sweetErr("No se pudo actualizar","");
              }
            });
          }else {
            this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());}
          break;
      }
    } catch (ex) { console.log(ex);}
  }

  btnEditar(e: any) {
    this.actualiza = true;
    this.accesoForm.patchValue({
      id_acceso: e.rowData.id_acceso,
      nom_acceso: e.rowData.nom_acceso,
      path_acceso: e.rowData.path_acceso,
      icono_acceso: e.rowData.icono_acceso,
    });
    this.EnableOrDisableInputForm('Enable');
  }


  /////////////////////////////
  public findInvalidControls() {
    const invalid = [];
    const controls = this.accesoForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(" "+name);
      }
    }
    return invalid;
  }

  customSpacesValidator(maxSpaces: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const spaces = control.value.split(' ').length - 1;
      if (spaces > maxSpaces) {
        return { spaces: true };
      }
      return null;
    };
  }

  EnableOrDisableInputForm (tipo: string) {
    if (tipo == "Enable") {
      this.accesoForm.controls['id_acceso'].enable();
      this.accesoForm.controls['nom_acceso'].enable();
      this.accesoForm.controls['path_acceso'].enable();
      this.accesoForm.controls['icono_acceso'].enable();
      this.accesoForm.controls['id_sistema'].disable();
    }
    else {
      this.accesoForm.controls['id_acceso'].disable();
      this.accesoForm.controls['nom_acceso'].disable();
      this.accesoForm.controls['path_acceso'].disable();
      this.accesoForm.controls['icono_acceso'].disable();
      this.accesoForm.controls['id_sistema'].enable();
    }
  }

}
