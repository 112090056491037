import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Encripta } from 'src/app/models/interface/encripta.interface';
import { RecuperaContraseña } from 'src/app/models/interface/recuperaContrasena.interface';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';
import { Modal } from 'bootstrap'
import { VerificaCodigo } from 'src/app/models/interface/verificaCodigo.interface';
import { FinRecuperaContrasena } from 'src/app/models/interface/finRecuperaContrasena.interface';

@Component({
  selector: 'app-recupera-contrasena',
  templateUrl: './recupera-contrasena.component.html',
  styleUrls: ['./recupera-contrasena.component.scss']
})
export class RecuperaContrasenaComponent {

  mensajesModals: Modal | undefined;
  recupera: boolean = true;
  okRecupera: boolean = false;
  msjeExito: boolean = true;
  msjeHijo: any;
  mostrarBoton: boolean = false;
  fieldTextType: boolean = false;
  botonDeshabilitado: boolean = false;

  recuperaContrasenaForm: UntypedFormGroup = new UntypedFormGroup({
    rut: new UntypedFormControl('', [Validators.required,  Validators.minLength(11), Validators.maxLength(12)]),
    usuario: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]),
  });

  codigoVerificaForm: UntypedFormGroup = new UntypedFormGroup({
    codVerifica: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
  });

  contrasenaForm: UntypedFormGroup = new UntypedFormGroup({
    nPass: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!¡#%*ñ()+¿?&])[A-Za-z\d$@!¡#%*ñ()+¿?&].{8,}'), Validators.maxLength(30)]), //Mínimo ocho caracteres, al menos una letra mayúscula, una letra minúscula, un número y un caracter especial
    cPass: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(30)])
  });

  constructor(private router: Router, private servicios: ServiceService, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {

    var session = sessionStorage.getItem('session_token');
    if (session !== undefined && session !== null) {
      this.jwt.setToken(session);
      if (this.jwt.isTokenExpired()) {
        this.jwt.removeToken();
      } else {
        this.router.navigate(['/home']);
      }
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.exitoMsje();
  }

  recuperaLogin() {
    try {
      if (typeof (Storage) !== 'undefined') {
            let _data: RecuperaContraseña = {
              id_sistema: 1,
              rut_usuario: this.recuperaContrasenaForm.get('rut')?.value,
              id_usuario: this.recuperaContrasenaForm.get('usuario')?.value
            };
            this.servicios.serviciosSeguridad('recupera-contrasena',_data).subscribe(res => {
              if (res.estado === 'OK') {
                this.realizarAccion();
                this.openModal('recupera');
                this.ocultaBoton();
              } else {
                this.sweetAlert.sweetErr("Ups", "No se pudo recuperar la contraseña");
              }
            },
            (error) => {this.sweetAlert.sweetErr("Ups", "No fue posible recuperar contraseña --> " + error.error.resultado);
            });
      } else {
        this.sweetAlert.sweetWarning("Favor", "Utilice otro navegador");
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  codigoVerifica() {
    try {
      if (typeof (Storage) !== 'undefined') {
            let _data: VerificaCodigo = { id_sistema: 1, rut_usuario: this.recuperaContrasenaForm.get('rut')?.value, id_usuario: this.recuperaContrasenaForm.get('usuario')?.value, cod_random: this.codigoVerificaForm.get('codVerifica')?.value };
            this.servicios.serviciosSeguridad('valida-recupera-contrasena',_data).subscribe(res => {
              if (res.estado === 'OK') {
                this.realizarAccion();
                this.closeModal();
                this.recupera = false;
                this.okRecupera = true;
              } else {
                this.sweetAlert.sweetErr("Ups", "No se pudo reestrablecer la contraseña");
                this.closeModal();
              }
            },
            (error) => {
              this.sweetAlert.sweetErr("Ups", "El código ingresado al parecer no es correcto --> " + error.error.resultado);
            });
      } else {
        this.sweetAlert.sweetWarning("Favor", "Utilice otro navegador");
        this.closeModal();
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  finalizaRecupera() {
    try {
      if (typeof (Storage) !== 'undefined') {
        let _encrypt: Encripta = { id_sistema: 1, valor_encriptar: this.contrasenaForm.get('cPass')?.value };
        this.servicios.encripta(_encrypt).subscribe(res => {
          if (res.estado === 'OK') {
            let _data: FinRecuperaContrasena = { 
              id_sistema: 1, rut_usuario: this.recuperaContrasenaForm.get('rut')?.value, id_usuario: this.recuperaContrasenaForm.get('usuario')?.value,
              cod_random: this.codigoVerificaForm.get('codVerifica')?.value, nueva_contrasena: res.resultado.encriptado 
            };
            this.servicios.serviciosSeguridad('finaliza-recupera-contrasena',_data).subscribe(res => {
              if (res.estado === 'OK') {
                this.recupera = true;
                this.okRecupera = false;
                this.sweetAlert.sweetOK("¡Tu contraseña esta lista!","La contraseña fue restablecida exitosamente. Ahora puedes acceder con tu correo y contraseña a la plataforma");
                this.router.navigate(['/']);
              } else {
                this.sweetAlert.sweetErr("Ups", "No se pudo recuperar la contraseña");
                this.recupera = true;
                this.okRecupera = false;
              }
            },
            (error) => {
              this.sweetAlert.sweetErr("Ups", error.error.resultado);
            });
          } else {
            this.sweetAlert.sweetErr("Ups", "Error al recuperar contraseña");
          }
        },
        (error) => {
          this.sweetAlert.sweetErr("Ups", error.error.resultado);
        });
      } else {
        this.sweetAlert.sweetWarning("Favor", "Utilice otro navegador");
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  comparaContrasena() {
    let pass_1 = this.contrasenaForm.value.nPass;
    let pass_2 = this.contrasenaForm.value.cPass;
    if (pass_1 !== pass_2 || pass_2 !== pass_1) {
      this.contrasenaForm.controls['cPass'].setErrors({ 'incorrect': true });
    } else {
      this.contrasenaForm.controls['cPass'].setErrors(null);
    }
  }

  navega() {
      this.router.navigate(['/']);
  }

  muestraPass() {
    this.fieldTextType = !this.fieldTextType;
  }

  openModal(modal: any) {
    var el_testModal = document.getElementById(modal);
    if (el_testModal) {
      this.mensajesModals = new Modal(el_testModal, {
        keyboard: false
      });
    }
    this.mensajesModals?.show();
  }

  closeModal() {
    this.mensajesModals?.hide();
  }

  ocultaBoton(){
    setTimeout(() => {
      this.mostrarBoton = true; // Mostrar el botón después de un minuto
    }, 10000); // 60000 ms = 1 minuto
  }

  realizarAccion() { // Simular una acción que lleva tiempo
    if (!this.botonDeshabilitado) {
      this.botonDeshabilitado = true;
      this.simularAccionLarga().then(() => {
        this.botonDeshabilitado = false;
      });
    }
  }

  simularAccionLarga(): Promise<void> {// Simular una acción que lleva tiempo (9 segundo)
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 9000);
    });
  }

  exitoMsje() {
    if (this.msjeHijo == true) {
      this.msjeExito = true;
      this.openModal('exitoMsje');
    }
  }

  onRutInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const formattedValue = this.formatRut(inputValue);
    
    this.recuperaContrasenaForm.get('rut')?.setValue(formattedValue, { emitEvent: false });
  }

  formatRut(rut: string): string {
    const cleanRut = rut.replace(/[^\dkK0-9]/g, '').toUpperCase();
    const rutPart = cleanRut.slice(0, -1);
    const dvPart = cleanRut.slice(-1);
  
    return rutPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '-' + dvPart;
  }

}
