import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CambiaContraseña } from 'src/app/models/interface/cambiaContrasena.interface';
import { Encripta } from 'src/app/models/interface/encripta.interface';
import { RecuperaContraseña } from 'src/app/models/interface/recuperaContrasena.interface';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';

@Component({
  selector: 'app-cambio-contrasena',
  templateUrl: './cambio-contrasena.component.html',
  styleUrls: ['./cambio-contrasena.component.scss']
})
export class CambioContrasenaComponent {

  fieldTextType: boolean = false;

  cambioContrasenaForm: UntypedFormGroup = new UntypedFormGroup({
    contrasena: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]),
    contrasenaNueva: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(30), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!¡#%*ñ()+¿?&])[A-Za-z\d$@!¡#%*ñ()+¿?&].{8,}')]),
  })

  constructor(private router: Router, private servicios: ServiceService, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {}

  ngOnInit(): void {
  }

  cambiarContrasena() {
    if (typeof (Storage) !== 'undefined') {
      let _encrypt: Encripta = { id_sistema: 1, valor_encriptar: this.cambioContrasenaForm.get('contrasena')?.value };
      this.servicios.encripta(_encrypt).subscribe(res => {
        if (res.estado === 'OK') {
          const encryptPass = res.resultado.encriptado;
          let _encrypt: Encripta = { id_sistema: 1, valor_encriptar: this.cambioContrasenaForm.get('contrasenaNueva')?.value };
          this.servicios.encripta(_encrypt).subscribe(res => {
            if (res.estado === 'OK') {
              const encryptNewPass = res.resultado.encriptado;
              let _data: CambiaContraseña = { id_sistema: 1, id_usuario: this.jwt.getUser(), contrasena: encryptPass, nueva_contrasena: encryptNewPass };
              this.servicios.serviciosSeguridad('cambia-contrasena',_data).subscribe(res => {
                if (res.estado === 'OK') {
                  this.cambioContrasenaForm.reset();
                  this.sweetAlert.sweetOK("Exito","Su contraseña fue cambiada exitosamente");
                } else {
                  this.sweetAlert.sweetErr("Ups", "No se pudo cambiar la contraseña");
                }
              },
                (error) => {
                  this.sweetAlert.sweetErr("Ups", "No fue posible cambiar la contraseña --> " + error.error.resultado);
                });
            } else {
              this.sweetAlert.sweetErr("Ups", "Error al cambiar contraseña");
            }
          },
            (error) => {
              this.sweetAlert.sweetErr("Ups", "No fue posible cambiar la contraseña --> " + error.error.resultado);
            });
        } else {
          this.sweetAlert.sweetErr("Ups", "Error al cambiar contraseña");
        }
      },
        (error) => {
          this.sweetAlert.sweetErr("Ups", "No fue posible cambiar la contraseña --> " + error.error.resultado);
        });
    } else {
      this.sweetAlert.sweetWarning("Favor", "Utilice otro navegador");
    }
  }

  muestraPass() {
    this.fieldTextType = !this.fieldTextType;
  }

  limpiar(){
    this.cambioContrasenaForm.get('contrasena')?.setValue('');
    this.cambioContrasenaForm.get('contrasenaNueva')?.setValue('');
    this.cambioContrasenaForm.get('contrasena')?.markAsUntouched();
    this.cambioContrasenaForm.get('contrasenaNueva')?.markAsUntouched();
  }

}
