export class Usuario {
    constructor(
        public tipo: string, 
        public rut_usuario: string, 
        public id_usuario: string, 
        public nombre: string,
        public ape_paterno: string,
        public ape_materno: string,
        public email: string,
        public funcionario : boolean,
        public nombre_usuario: string,
        public nombre_completo: string
    ) {}
}