
<!-- <div class="row">
    <div class="content">
        <form id="formLogin" class="contact-form" [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="offset-4 col-md-4">
                <p>Modulo de Seguridad</p>
                <div class="mb-3">
                    <label for="txtUsuario">Usuario</label>
                    <input formControlName="usuario" type="email" class="form-control" id="txtUsuario" autocomplete="off">
                </div>
                <div class="mb-3">
                    <label for="txtContrasena">Contraseña</label>
                    <input formControlName="contrasena" type="password" class="form-control" id="txtContrasena" autocomplete="off">
                </div>
                <button type="submit" name="submit" id="submit" value="Submit" class="btn btn-primary" [disabled]="loginForm.invalid">Ingresar</button>
            </div>
        </form>
    </div>
</div> -->

<div class="container">
<div class="row">
    <div class="col">
        <br><br><br><br>
        <div class="wrapper">
            <form id="formLogin" class="contact-form" [formGroup]="loginForm" (ngSubmit)="login()">
              <h1>Módulo de seguridad</h1>
              <h5>AGP SA.</h5>
              <hr class="sep"/>
              <div class="group">
                <input formControlName="usuario" type="email" required="required"/><span class="highlight"></span><span class="bar"></span>
                <label>Email</label>
              </div>
              <div class="group">
                <input id="pass" formControlName="contrasena" type="password" [type]="fieldTextType ? 'text' : 'password'" required/>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Contraseña</label>
                <span class="left-icon" (click)="muestraPass()">
                  <i class="fa enlace"
                    [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}">
                  </i>
                </span>
              </div>
              <div class="btn-box d-grid gap-2 btn-box">
                <button class="btn btn-submit" type="submit" [disabled]="loginForm.invalid">Ingresar</button>
              </div>
              <div class="text-end">
                <span class="ml-auto">
                    <a (click)="recuperaContrasena()" class="forgot-pass text-muted enlace-como-span pe-3"
                        style="font-size:14px;">Recuperar contraseña</a>
                </span>
            </div>
            </form>
          </div>

          
    </div>
    
</div>
</div>
