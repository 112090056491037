<div class="container">
    <div class="col-5 mt-sm-4 mt-3 mb-sm-3 mb-2">
        <h3>Modulo de Seguridad</h3>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col">
            <div class="card-principal">
                <div class="container">
                    <div class="row">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <h1>Encriptación RSA</h1>
                                </button>
                              </h2>
                              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="spacer-20"></div>
                                <div class="row">
                                    <p>Para utilizar las llaves RSA, se deben instalar las librerias <code>Portable.BouncyCastle</code> y <code>BouncyCastle.NetCore</code></p>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="row">
                                    <ul class="nav nav-tabs" id="tab-portable" role="tablist">
                                        <li class="nav-item" role="presentation">
                                          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Package Manager</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">.NET CLI</button>
                                        </li>
                                      </ul>
                                      <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="spacer-20"></div>
                                            <code>Install-Package Portable.BouncyCastle</code>
                                        </div>
                                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <div class="spacer-20"></div>
                                            <code>dotnet add package Portable.BouncyCastle</code>
                                        </div>
                                      </div>
                                </div>
                                <hr>
                                <div class="spacer-20"></div>
                                <div class="row">
                                    <ul class="nav nav-tabs" id="tab-netcore" role="tablist">
                                        <li class="nav-item" role="presentation">
                                          <button class="nav-link active" id="home-tab-v2" data-bs-toggle="tab" data-bs-target="#homev2" type="button" role="tab" aria-controls="homev2" aria-selected="true">Package Manager</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <button class="nav-link" id="profile-tab-v2" data-bs-toggle="tab" data-bs-target="#profilev2" type="button" role="tab" aria-controls="profilev2" aria-selected="false">.NET CLI</button>
                                        </li>
                                      </ul>
                                      <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="homev2" role="tabpanel" aria-labelledby="home-tab-v2">
                                            <div class="spacer-20"></div>
                                            <code>Install-Package BouncyCastle.NetCore</code>
                                        </div>
                                        <div class="tab-pane fade" id="profilev2" role="tabpanel" aria-labelledby="profile-tab-v2">
                                            <div class="spacer-20"></div>
                                            <code>dotnet add package BouncyCastle.NetCore</code>
                                        </div>
                                      </div>
                                </div>
                                <hr>
                                <div class="spacer-20"></div>       
                                <div class="row">
                                    <p>Luego de la instalacion, se deben implementar los siguientes metodos, utilizando las llaves previamente adquiridas en la creacion del Sistema.</p>
                                </div>
                                <div class="spacer-20"></div>
                                <div class="row">
                                    <div class="col">
                                        <div [innerHTML]="code_encriptar"></div>
                                    </div>
                                    <div class="col">
                                        <div [innerHTML]="code_desencriptar"></div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <h1>LOG</h1>
                                  </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                                </div>
                            </div>
                            <div class="spacer-20"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

