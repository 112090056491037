import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Encripta } from 'src/app/models/interface/encripta.interface';
import { Login } from 'src/app/models/interface/login.interface';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup = new UntypedFormGroup({
    usuario: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    contrasena: new UntypedFormControl('', Validators.required),
  })
  fieldTextType: boolean = false;

  constructor(private router: Router, private servicios: ServiceService, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {

      var session = sessionStorage.getItem('session_token');
      if (session !== undefined && session !== null) {
        this.jwt.setToken(session);
        if (this.jwt.isTokenExpired()) {
          this.jwt.removeToken();
        } else {
          this.router.navigate(['/home']);
        }      
    }
  }

  ngOnInit(): void {
  }


  login() {
    if (typeof (Storage) !== 'undefined') {
      let _encrypt: Encripta = { id_sistema: 1, valor_encriptar: this.loginForm.get('contrasena')?.value };
      this.servicios.encripta(_encrypt).subscribe(res => {
        if (res.estado === 'OK') {
          let _usu: Login = { usuario: this.loginForm.get('usuario')?.value, contrasena: res.resultado.encriptado };
          this.servicios.login(_usu).subscribe(res => {
            if (res.estado === 'OK') {
              sessionStorage.setItem('session_token', res.token);
              this.router.navigate(['/home/inicio']);
            } else {
              this.sweetAlert.sweetErr("Ups","Usuario o Contraseña INCORRECTOS");
            }
          },
            (error) => {
              this.sweetAlert.sweetErr("Ups", error);
            });
        } else {
          this.sweetAlert.sweetErr("Ups","Error al Encriptar");
        }
      },
        (error) => {
          this.sweetAlert.sweetErr("Ups", error);
        });
    } else {
      this.sweetAlert.sweetWarning("Favor","Utilice otro navegador");
    }
  }

  recuperaContrasena()
  {
    this.router.navigate(['/recuperar-contrasena'])
  }

  muestraPass() {
    this.fieldTextType = !this.fieldTextType;
  }
}

