import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Usuario } from 'src/app/models/class/usuario.class';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';
import { BotonGrillaComponent } from '../componentes-grilla/boton-grilla/boton-grilla.component';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  idUsuario: string = '';
  bodyUsuario = new Usuario('', '', '', '', '', '', '', false, '', '');
  actualiza: boolean = false;
  btnAgrega: boolean = false;
  frameworkComponents: any;
  columnas_grilla: ColDef[] = [
    { field: 'rut_usuario', headerName: 'Rut Usuario' },
    { field: 'id_usuario', headerName: 'ID Usuario' },
    { field: 'nombre_completo', headerName: 'Nombre Completo' },
    {
      field: '',
      cellRenderer: 'botonRender',
      cellRendererParams: {
        onClick: this.btnEditar.bind(this),
        label: 'Editar'
      },
      cellStyle: { textAlign: "center" }
    }
  ];
  datosGrilla = [];
  usuarioForm: UntypedFormGroup = new UntypedFormGroup({
    tipo: new UntypedFormControl(),
    rut_usuario: new UntypedFormControl('', [Validators.required, this.rutChilenoValidator(), Validators.minLength(11), Validators.maxLength(12)]),
    id_usuario: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
    nombre: new UntypedFormControl('', [Validators.minLength(3), Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]*$'), this.customSpacesValidator(2)]),
    ape_paterno: new UntypedFormControl('', [Validators.minLength(3), Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]*$')]),
    ape_materno: new UntypedFormControl('', [Validators.minLength(3), Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]*$')]),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    funcionario: new UntypedFormControl(false),
    nombre_usuario: new UntypedFormControl()
  });

  constructor(private servicios: ServiceService, private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {
    if (!this.jwt.isTokenExpired()) {
      this.frameworkComponents = {
        botonRender: BotonGrillaComponent,
      }
      this.bodyUsuario.nombre_usuario = this.jwt.getUser();
    }
  }

  ngOnInit(): void {
    this.usuarioForm.value.funcionario = false;
    this.usuarioForm.get('funcionario')?.setValue(false);
    this.cargaUsuario();
  }
  onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }

  cargaUsuario(): void {
    try {
      let body: Usuario = { tipo: 'Seleccionar', rut_usuario: '', id_usuario: '', nombre: '', ape_paterno: '', ape_materno: '', email: '', funcionario: false, nombre_usuario: this.jwt.getUser(), nombre_completo: '' };
      this.servicios.mantenedorSeguridad(body, 'usuario').subscribe(res => {
        if (res.estado === 'OK') {
          this.datosGrilla = res.resultado;
        }
      });
    }
    catch (ex) { console.log(ex); }
  }
  limpiarUsuario(): void {
    this.usuarioForm.patchValue({
      rut_usuario: '',
      id_usuario: '',
      nombre: '',
      ape_paterno: '',
      ape_materno: '',
      email: '',
      funcionario: this.usuarioForm.get('funcionario')?.setValue(false),
    });
    this.actualiza = false;
    this.btnAgrega = false;
    this.idUsuario = '';
    this.cargaUsuario();
    this.EnableOrDisableInputForm("Enable");
    this.usuarioForm.get('rut_usuario')?.markAsUntouched();
    this.usuarioForm.get('id_usuario')?.markAsUntouched();
    this.usuarioForm.get('nombre')?.markAsUntouched();
    this.usuarioForm.get('ape_paterno')?.markAsUntouched();
    this.usuarioForm.get('ape_materno')?.markAsUntouched();
    this.usuarioForm.get('email')?.markAsUntouched();
  }
  mantenedorUsuario(tipo: string) {
    switch (tipo) {
      case "ELIMINAR":
        if (confirm("¿Esta seguro que desea Eliminar?")) {
          if (this.usuarioForm.valid) {
            this.bodyUsuario.tipo = 'Eliminar';
            this.bodyUsuario.rut_usuario = this.usuarioForm.get('rut_usuario')?.value.toUpperCase();
            this.servicios.mantenedorSeguridad(this.bodyUsuario, 'usuario').subscribe(res => {
              if (res.estado === 'OK') {
                this.sweetAlert.sweetOK("Usuario eliminado","");
                this.limpiarUsuario();
                this.cargaUsuario();
              } else {
                this.sweetAlert.sweetErr("No se pudo eliminar","");
              }
            });
          } else {  }
        } else { }
        break;
      case "AGREGAR":
        if (this.usuarioForm.valid) {
          this.bodyUsuario.tipo = 'Insertar';
          this.bodyUsuario.rut_usuario = this.usuarioForm.get('rut_usuario')?.value.toUpperCase();
          this.bodyUsuario.id_usuario = this.usuarioForm.get('id_usuario')?.value;
          this.bodyUsuario.nombre = this.usuarioForm.get('nombre')?.value.toUpperCase();
          this.bodyUsuario.ape_paterno = this.usuarioForm.get('ape_paterno')?.value.toUpperCase();
          this.bodyUsuario.ape_materno = this.usuarioForm.get('ape_materno')?.value.toUpperCase();
          this.bodyUsuario.email = this.usuarioForm.get('email')?.value;
          this.bodyUsuario.funcionario = this.usuarioForm.get('funcionario')?.value;     
          this.servicios.mantenedorSeguridad(this.bodyUsuario, 'usuario').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Usuario ingresado","");
              this.limpiarUsuario();
              this.cargaUsuario();
            } else {
              this.sweetAlert.sweetWarning("Usuario no pudo ser ingresado","");
            }
          });
        } else { 
          this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());}
        break;
      case "ACTUALIZAR":
        if (this.usuarioForm.valid) {
          this.bodyUsuario.tipo = 'Actualizar';
          this.bodyUsuario.rut_usuario = this.usuarioForm.get('rut_usuario')?.value.toUpperCase();
          this.bodyUsuario.id_usuario = this.usuarioForm.get('id_usuario')?.value;
          this.bodyUsuario.nombre = this.usuarioForm.get('nombre')?.value.toUpperCase();
          this.bodyUsuario.ape_paterno = this.usuarioForm.get('ape_paterno')?.value.toUpperCase();
          this.bodyUsuario.ape_materno = this.usuarioForm.get('ape_materno')?.value.toUpperCase();
          this.bodyUsuario.email = this.usuarioForm.get('email')?.value;
          this.bodyUsuario.funcionario = this.usuarioForm.get('funcionario')?.value;
          this.servicios.mantenedorSeguridad(this.bodyUsuario, 'usuario').subscribe(res => {
            if (res.estado === 'OK') {
              this.sweetAlert.sweetOK("Usuario actualizado","");
              this.limpiarUsuario();
              this.cargaUsuario();
            } else {
              this.sweetAlert.sweetWarning("Usuario no pudo ser actualizado","");
            }
          });
        } else {
          this.sweetAlert.sweetWarning("No se pudo realizar la acción","Complete : " + this.findInvalidControls().toString());
        }
        break;
    }
  }
  btnEditar(e: any) {
    this.actualiza = true;
    this.usuarioForm.patchValue({
      rut_usuario: e.rowData.rut_usuario,
      id_usuario: e.rowData.id_usuario,
      nombre: e.rowData.nombre,
      ape_paterno: e.rowData.ape_paterno,
      ape_materno: e.rowData.ape_materno,
      email: e.rowData.email,
      funcionario: e.rowData.funcionario,
    });
    this.EnableOrDisableInputForm("Enable");
  }

  buscarUsuario(tipo: string): void {
    try {
      if (this.usuarioForm.get('rut_usuario')?.value && this.usuarioForm.get('rut_usuario')?.value.trim() !== '' || this.usuarioForm.get('id_usuario')?.value && this.usuarioForm.get('id_usuario')?.value.trim() !== '') {
      let body:any;
      if (tipo == "rut") {
        let rut = this.usuarioForm.get('rut_usuario')?.value.toUpperCase();
        let bodys: Usuario = { tipo: 'Seleccionar', rut_usuario: rut, id_usuario: '', nombre: '', ape_paterno: '', ape_materno: '', email: '', funcionario: false, nombre_usuario: this.jwt.getUser(), nombre_completo: '' };
        body = bodys;
      }
      if (tipo == "usuario") {
        let bodys: Usuario = { tipo: 'Seleccionar', rut_usuario: '', id_usuario: this.usuarioForm.get('id_usuario')?.value, nombre: '', ape_paterno: '', ape_materno: '', email: '', funcionario: false, nombre_usuario: this.jwt.getUser(), nombre_completo: '' };
        body = bodys;
      }

      this.servicios.mantenedorSeguridad(body, 'usuario').subscribe(res => {
        if (res.estado === 'OK') {
          if (res.resultado.length > 0) {
            this.datosGrilla = [];
            this.datosGrilla = res.resultado;
            this.EnableOrDisableInputForm("Disable");
            this.btnAgrega = true;
            this.usuarioForm.patchValue({
             rut_usuario: res.resultado[0].rut_usuario,
             id_usuario: res.resultado[0].id_usuario,
             nombre: res.resultado[0].nombre,
             ape_paterno: res.resultado[0].ape_paterno,
             ape_materno: res.resultado[0].ape_materno,
             email: res.resultado[0].email,
             funcionario: res.resultado[0].funcionario,
            });
          } else {
            this.sweetAlert.sweetWarning("Usuario no encontrado","");
          }
        } else {
          this.sweetAlert.sweetWarning("Usuario no encontrado","");
        }
      });
    };
    }
    catch (ex) { console.log(ex); }

  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.usuarioForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(" "+name);
      }
    }
    return invalid;
  }

  customSpacesValidator(maxSpaces: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const spaces = control.value.split(' ').length - 1;
      if (spaces > maxSpaces) {
        return { spaces: true };
      }
      return null;
    };
  }

  EnableOrDisableInputForm (tipo: string) {
    if (tipo == "Enable") {
      this.usuarioForm.controls['rut_usuario'].enable();
      this.usuarioForm.controls['id_usuario'].enable();
      this.usuarioForm.controls['nombre'].enable();
      this.usuarioForm.controls['ape_paterno'].enable();
      this.usuarioForm.controls['ape_materno'].enable();
      this.usuarioForm.controls['email'].enable();
      this.usuarioForm.controls['funcionario'].enable();
      this.usuarioForm.controls['nombre_usuario'].enable();
    }
    else {
      this.usuarioForm.controls['rut_usuario'].disable();
      this.usuarioForm.controls['id_usuario'].disable();
      this.usuarioForm.controls['nombre'].disable();
      this.usuarioForm.controls['ape_paterno'].disable();
      this.usuarioForm.controls['ape_materno'].disable();
      this.usuarioForm.controls['email'].disable();
      this.usuarioForm.controls['funcionario'].disable();
      this.usuarioForm.controls['nombre_usuario'].disable();
    }
  }


  rutChilenoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // El campo está vacío, no hay validación necesaria
      }
  
      const rut = control.value.replace(/\./g, '').toUpperCase();
      const pattern = /^\d{1,2}(\.\d{3}){2}-[\dkK]$/;
  
      if (pattern.test(rut)) {
        return { rutInvalido: true };
      }
  
      const [numero, digitoVerificador] = rut.split('-');
      const dvCalculado = this.calcularDigitoVerificador(numero);
  
      if (dvCalculado !== digitoVerificador.toUpperCase()) {
        return { rutInvalido: true };
      }
  
      return null;
    };
  }
  
  formatRut(rut: string): string {
    const cleanRut = rut.replace(/[^\dkK0-9]/g, '').toUpperCase();
    const rutPart = cleanRut.slice(0, -1);
    const dvPart = cleanRut.slice(-1);
  
    return rutPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '-' + dvPart;
  }
  
  calcularDigitoVerificador(numero: string): string {
    let suma = 0;
    let factor = 2;
  
    for (let i = numero.length - 1; i >= 0; i--) {
      suma += parseInt(numero.charAt(i)) * factor;
      factor = factor === 7 ? 2 : factor + 1;
    }
  
    const digitoVerificador = 11 - (suma % 11);
    return digitoVerificador === 10 ? 'K' : digitoVerificador === 11 ? '0' : digitoVerificador.toString();
  }
  
  onRutInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const formattedValue = this.formatRut(inputValue);
    
    this.usuarioForm.get('rut_usuario')?.setValue(formattedValue, { emitEvent: false });
  }

}
