import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/services/jwt.service';
import { HighlightService } from 'src/app/services/highlight.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})

export class InicioComponent implements OnInit, AfterViewChecked {
  code_encriptar: any = `<pre><code class=\"language-csharp\">private string RsaEncryptWithPublic(string clearText, string public_key)\n{\n\tvar _publicKey = $"-----BEGIN PUBLIC KEY-----\\n{public_key}\\n-----END PUBLIC KEY-----\\n";\n\tPemReader pr = new PemReader(new StringReader(_publicKey));\n\tAsymmetricKeyParameter publicKey = (AsymmetricKeyParameter)pr.ReadObject();\n\tRSAParameters rsaParams = DotNetUtilities.ToRSAParameters((RsaKeyParameters)publicKey);\n\n\tRSACryptoServiceProvider csp = new RSACryptoServiceProvider();\n\tcsp.ImportParameters(rsaParams);\n\n\tvar bytesPlainTextData = System.Text.Encoding.Unicode.GetBytes(clearText);\n\tvar bytesCypherText = csp.Encrypt(bytesPlainTextData, false);\n\tvar cypherText = Convert.ToBase64String(bytesCypherText);\n\treturn cypherText;\n}</code></pre>`;
  code_desencriptar: any = `<pre><code class=\"language-csharp\">private string RsaDecryptWithPrivate(string base64Input, string private_key)\n{\n\tvar privateKey = $"-----BEGIN PRIVATE KEY-----\\n{private_key}\\n-----END PRIVATE KEY-----\\n";\n\tPemReader pr = new PemReader(new StringReader(privateKey));\n\tRsaPrivateCrtKeyParameters KeyPair = (RsaPrivateCrtKeyParameters)pr.ReadObject();\n\tRSAParameters rsaParams = DotNetUtilities.ToRSAParameters(KeyPair);\n\n\tRSACryptoServiceProvider csp = new RSACryptoServiceProvider();\n\tcsp.ImportParameters(rsaParams);\n\n\tvar bytesCypherText = Convert.FromBase64String(base64Input);\n\tvar bytesPlainTextData = csp.Decrypt(bytesCypherText, false);\n\tvar plainTextData = System.Text.Encoding.Unicode.GetString(bytesPlainTextData);\n\treturn plainTextData;\n}</code></pre>`;

  highlighted: boolean = false;

  constructor(private router: Router, private jwt: JwtService, private highlightService: HighlightService, private sweetAlert: Sweetalert2Service) {}

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    if (!this.highlighted) {
      this.highlightService.highlightAll();
      this.highlighted = true;
    }
  }

}
