<div class="container">
    <div class="col-5 mt-sm-4 mt-3 mb-sm-3 mb-2">
        <h3>Mantenedor Perfil</h3>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col">
            <div class="card-principal">
                <form id="formPerfil" class="contact-form" [formGroup]="perfilForm">
                    <div class="container mt-sm-4">
                        <div class="row">
                            <div class="col">
                                <div class="form-floating">
                                    <select id="cbSistema" formControlName="id_sistema" class="form-select" placeholder="Seleccione Sistema" #t (change)="onChangeSistema(t.value)" required>
                                        <option *ngFor="let sistema of lt_sistema" [value]="sistema.id_sistema">
                                          {{sistema.nom_sistema}}
                                        </option>
                                    </select>
                                    <label for="cbSistema">Sistema</label>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-20"></div>
                        <div class="row">
                            <div class="col">
                                <fieldset disabled>
                                <div class="form-floating">
                                    <input type="text" formControlName="id_perfil"
                                        class="form-control text-uppercase form-control-sm" id="txtIdPerfil"
                                        placeholder="" autocomplete="off" maxlength="30"
                                        minlength="4" readonly>
                                    <label for="txtIdPerfil">Id Perfil</label>
                                </div>
                                </fieldset>
                            </div>
                            <div class="col">
                                <div class="form-floating">
                                    <input type="text" formControlName="nom_perfil"
                                        class="form-control form-control-sm text-uppercase" id="txtNomPerfil"
                                        placeholder="" autocomplete="off" maxlength="30"
                                        minlength="4" readonly required>
                                        <div *ngIf="perfilForm.get('nom_perfil')?.hasError('pattern')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">solo se permiten letras de la A a la Z</p> 
                                        </div>
                                        <div *ngIf="perfilForm.get('nom_perfil')?.hasError('required') && perfilForm.get('nom_perfil')?.touched">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">campo requerido</p> 
                                        </div>
                                        <div *ngIf="perfilForm.get('nom_perfil')?.hasError('minlength') || perfilForm.get('nom_perfil')?.hasError('maxlength')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">minimo 4, máximo 30 caracteres</p> 
                                        </div>
                                        <div *ngIf="perfilForm.get('nom_perfil')?.hasError('spaces')">
                                            <p class="ms-3" style="color: rgb(227, 37, 107); font-size: 12px;">Se permiten como máximo tres espacios entre caracteres o palabras</p> 
                                        </div>
                                    <label for="txtNomPerfil">Nombre Perfil</label>
                                </div>
                            </div>
                            <!-- <div *ngIf="sistemForm.controls['sistema'].invalid && (sistemForm.controls['sistema'].dirty || sistemForm.controls['sistema'].touched)">
                                <div class="help-block with-errors text-start ps-1" style="color: red; font-size: 12px; ">
                                    No puede ser vació, contener espacios ni números (_ soportado) minlength 4 maxlength 30.
                                </div>
                            </div> -->
                        </div>
                        <div class="spacer-20"></div>
                        <div class="d-flex justify-content-left">
                            <div *ngIf="!actualiza" class="col-md-3 d-grid gap-2">
                                <button type="submit" class="btn btn-success btn-lg" [disabled]="btnAgregar" (click)="mantenedorPerfil('AGREGAR')"><i class="fas fa-plus-circle"></i>
                                    Agregar</button>
                            </div>
                            <div *ngIf="actualiza" class="col-md-3 d-grid gap-2" style="padding-left: 10px;" >
                                <button type="submit" class="btn btn-outline-success btn-lg" (click)="mantenedorPerfil('ACTUALIZAR')"><i class="fas fa-plus-circle"></i>
                                    Actualizar</button>
                            </div>
                            <div *ngIf="actualiza" class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-danger btn-lg" (click)="mantenedorPerfil('ELIMINAR')"><i class="fas fa-minus-circle"></i>
                                    Eliminar</button>
                            </div>
                            <div class="col-md-3 d-grid gap-2" style="padding-left: 10px;">
                                <button type="submit" class="btn btn-outline-warning btn-lg" (click)="limpiarPerfil()"><i class="fas fa-exclamation-circle"></i>
                                    Cancelar</button>
                            </div>
                        </div>
                        <div class="text-center border-top mb-3 mt-4"></div>
                        <h4 class="mb-3">Registros</h4>
                        <div class="row mt-sm-4 mt-2 mb-sm-2 ps-sm-3 pe-sm-3">
                            <div class="col-sm card-table">
                                <ag-grid-angular style="height: 300px;"class="ag-theme-alpine" 
                                [rowData]="datosGrilla" [columnDefs]="columnas_grilla" [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
                                (firstDataRendered)="onFirstDataRendered($event)"
                                >
                            </ag-grid-angular>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>