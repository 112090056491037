<div class="container-fluid overflow-hidden">
    <div class="row vh-100 overflow-auto">
        <div class="col-12 col-sm-3 col-xl-2 px-sm-2 px-0 bg-light d-flex sticky-top">
            <div class="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-3 pt-2 text-white">
                <div style="padding-top: 10px;">
                    <h5>
                        Modulo de Seguridad
                    </h5>
                </div>
                <ul class="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start"
                    id="menu">
                    <li class="nav-item">
                        <a routerLink='/home/inicio' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-house"></i><span class="ms-1 d-none d-sm-inline" >Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink='/home/sistema' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-speedometer2"></i><span class="ms-1 d-none d-sm-inline">Mantenedor Sistema</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink='/home/acceso' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-table"></i><span class="ms-1 d-none d-sm-inline">Mantenedor Acceso</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/perfil' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-grid"></i><span class="ms-1 d-none d-sm-inline">Mantenedor Perfil</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/usuario' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-people"></i><span class="ms-1 d-none d-sm-inline">Mantenedor Usuario</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/usuario-perfil' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-people"></i><span class="ms-1 d-none d-sm-inline">Mantenedor Usuario - Perfil</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/permiso' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-people"></i><span class="ms-1 d-none d-sm-inline">Mantenedor Permiso</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/parametro' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-people"></i><span class="ms-1 d-none d-sm-inline">Mantenedor Parametro</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/log' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link px-sm-0 px-2">
                            <i class="fs-5 bi-people"></i><span class="ms-1 d-none d-sm-inline">Panel Log</span>
                        </a>
                    </li>
                    <br><br><br>
                    <div class="col-12 d-grid gap-2 d-md-block">
                        <button type="button" class="btn btn-outline-primary" (click)="cambioContrasena()">Cambiar contraseña</button>
                    </div>
                    <br>
                    <div class="col-12 d-grid gap-2 d-md-block">
                        <button type="button" class="btn btn-secondary" (click)="exit()">Cerrar sesión</button>
                    </div>
                    
                </ul>
                
            </div>
        </div>
        <div class="col d-flex flex-column h-sm-100">
            <main>
                <router-outlet></router-outlet>
            </main>
        </div>
    </div>
</div>