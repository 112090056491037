import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Log } from 'src/app/models/class/log.class';
import { JwtService } from 'src/app/services/jwt.service';
import { ServiceService } from 'src/app/services/service.service';
import { Sweetalert2Service } from 'src/app/services/sweet-alert.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  bodyLog = new Log(0);
  columnas_grilla: ColDef[] = [
    { field: 'fecha_hora', headerName: "Fecha Hora" },
    { field: 'estado', headerName: "Estado" },
    { field: 'mensaje', headerName: "Mensaje" }
  ];
  datosGrilla = [];
  columnas_grillaServi: ColDef[] = [
    { field: 'fecha_hora', headerName: "Fecha Hora" },
    { field: 'estado', headerName: "Estado" },
    { field: 'end_point', headerName: "End Point" },
    { field: 'metodo', headerName: "Metodo" },
    { field: 'request', headerName: "Request" },
    { field: 'response', headerName: "Response" },
  ];
  datosGrillaServi = [];
  logForm: UntypedFormGroup = new UntypedFormGroup({
    id_session: new UntypedFormControl()
  });
  
  constructor(private servicios: ServiceService, private router: Router, private jwt: JwtService, private sweetAlert: Sweetalert2Service) {
    if (this.jwt.isTokenExpired()) {
      this.jwt.removeToken();
      this.router.navigate(['/']);
      this.sweetAlert.sweetWarning("Sesión Cerrada","Token Expirado.");
    }
  }

  ngOnInit(): void {
  }
  onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }

  buscarLog(): void {
    try {
      this.servicios.getLog(this.logForm.value.id_session).subscribe(res => {
        if (res.estado === 'OK') {
          this.datosGrilla = res.resultado[0];
          this.datosGrillaServi = res.resultado[1];
        }
      });
    }
    catch (ex) { console.log(ex); }
  }

  limpiarLog(): void {
    this.logForm.patchValue({
      id_session: '',
    });
    this.logForm.controls['id_session'].enable();
    this.bodyLog.id_session = 0;
  }
}
